
function replaceKeys(obj) {
    if (!obj || typeof obj !== 'object') return obj;

    if (Array.isArray(obj)) {
        return obj.map(item => replaceKeys(item));
    }

    let newObj = {};
    for (let key in obj) {
        let newKey = key.replace(/_DS_/g, "$");
        newObj[newKey] = replaceKeys(obj[key]);
    }

    return newObj;
}

export default function(config) {
    return {
        namespaced: true,
        state: {
            authenticated: false,
            login_email: "",
            login_password: "",
            access: false
        },
        mutations: {
            setAuthenticated: (state, s) => {
                state.authenticated = s
            },
            updateField: (state, p) => {
                state[p.field] = p.value
            },
            setLogin: (state, data) => {
                for (const key in data) {
                    state[key] = data[key]
                }
            },
        },
        actions: {
            async login({state, commit}) {
                const res = await this.API('account/login', {admin: true, email: state.login_email, password: state.login_password})
                state.login_password = ""
                if (res) {
                    res.authenticated = true
                    if (res.access) {
                        res.access = replaceKeys(res.access)
                    }
                    console.log("Login:", res)
                    commit('setLogin', res)
                    return true
                } else {
                    return false
                }
            },
            logout({commit}) {
                commit('setLogin', {authenticated: false, token: false, email: "", name: "", phone: "", access: false, login_password: "", login_message: ""})
            }
        },
    }
}
