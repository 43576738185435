module.exports = function(isProd) {
    return {
        appId: 'internetprint',
        title: 'InternetPrint.eu',
        logo: 'img/logo.svg',
        storeId: 'ipa',
        baseUrl: isProd ? 'https://admin.internetprint.eu/':'http://test.hu:3000/',
        api: {
            //store: isProd ? 'https://europe-west1-internetprint-api.cloudfunctions.net/api/' : 'http://test.hu:5001/internetprint-api/europe-west1/api/',
            store: isProd ? 'https://api.internetprint.eu/' : 'http://test.hu:5001/',
            cloud: isProd ? 'https://cloud.internetprint.eu/' : 'http://test.hu:3100/',
            notify: isProd ? 'https://process.internetprint.eu/' : 'http://test.hu:6700/'
        },
    }
}
