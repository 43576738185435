<template>
<div :class="cclasses">
<v-select
    ref="select"
    :class="'vselect '+classes"
    :options="options"
    label="name"
    :modelValue="value"
    :clearable="false"
    :searchable="searchable"
    append-to-body
    :calculate-position="calcPos"
    @update:modelValue="selectInput($event);$emit('input', $event)"
>
    <template #option="option">
        <div v-if="option.code!='--'">
          <div v-if="flags" :class="flagClassOf(option.code)"></div>
          <img v-if="option.image" :src="option.image">
          <div v-if="option.color" :class="'w-24 h-6 inline float-left mr-1 border border-gray border-opacity-10 '+(option.code == value.code || option.code == value.code+'-' ? 'ring-2 ring-offset-2' : '')" :style="'background:'+option.color"></div>
          <span :class="{'font-bold':option.code == value.code || option.code == value.code+'-'}">{{ option.name }}</span>
        </div>
        <div v-if="option.note" style="opacity: 0.7;" class="text-xs leading-3"><span v-html="option.note"></span></div>
        <hr v-if="option.code=='--'" />
    </template>

    <template #selected-option="option">
        <div>
          <div v-if="flags" :class="flagClassOf(option.code)"></div>
          <img v-if="option.image" :src="option.image">
          <div v-if="option.color" class="w-12 h-6 inline float-left mr-1 border border-gray border-opacity-10 " :style="'background:'+option.color"></div>
          {{ option.name }}
        </div>
    </template>

    <template #no-options="{ search, searching }">
         <template v-if="searching">{{ $tr('not_found') }}: <em>{{search}}</em></template>
         <em style="opacity: 0.5;" v-else>{{ $tr('select_or_search') }}</em>
    </template>

    <template #open-indicator="{ attributes }">
      <div v-bind="attributes"><Icon :code="icon" classes="sm"/></div>
    </template>

</v-select>
</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Icon from "@/components/Icon.vue";

export default {
  name: 'Select',
  components: {
    'v-select': vSelect,
    'Icon':Icon
  },
  props: {
    value: Object,
    options: Array,
    flags: Boolean,
    searchable: {type: Boolean, default: true},
    minwidth: {type: Number, default: 0},
    classes: String,
    cclasses: String,
    icon: {type: String, default: 'expand'}
  },
  mounted() {
    this.selectInput(this.value ? this.value : {})
    //console.log('select mounted')
  },
  methods: {
    flagClassOf(cc) {
        cc = cc.replace(/-/gi,'')
        let clss = { fflag: true };
        clss["fflag-" + cc] = true;
        return clss;
    },
    calcPos(dropdownList, component, {width, top, left}) {
      //console.log(component)
      //console.log("calcPos", width, top, left)
      if (!dropdownList) {return}
      let height = dropdownList.clientHeight
      let wh = window.innerHeight
      let dtop = parseInt(top)
      if (dtop + height > wh) {
          dtop = dtop - dropdownList.clientHeight - 48
      }
      if (dtop < 48) {
          dtop = (wh - height) / 2
      }

      let ww = window.innerWidth
      let dwidth = parseInt(width)
      let fwidth = dwidth
      let dleft = parseInt(left)

      if (dleft + fwidth > ww - 8) {
          dleft = ww - fwidth - 8
      }
      if (dleft < 16) {
          dleft = 16
      }

      dropdownList.style.top = dtop + 'px';
      dropdownList.style.left = dleft + 'px';
      dropdownList.style.width = dwidth + 'px';
    },
    selectInput(e) {
      let len = 4
      if (this.minwidth) {
        len = this.minwidth * 0.65
      } else {
        if (e && e.name) {
          len = (''+e.name).length
        }
      }
      let menu = this.$refs.select.$el
      if (menu) {
        menu.style.minWidth = (len+1)+'em'
      }
    },
  }
}
</script>

<style lang="scss">
.vselect .vs__search::placeholder,
.vselect .vs__dropdown-toggle,
.vs__dropdown-menu {
    border: none;
    /*min-width: 8rem;*/
    @apply rounded-md bg-white;
    z-index: 10002;
}

.vs__selected-options {
  white-space: nowrap;
  height: 1.2rem;
}

.vs__dropdown-menu {
  /*min-width: 8rem;*/
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.vs--open.norotate .vs__open-indicator {
  transform: rotate(0deg) scale(1);
}

</style>