export default {
    data() {
        return {
            update: 0,
            loading: false,
            error: false,
            list: [],
        }
    },
    computed: {
        selected() {
            return this.$store.state.tables[this.table].selected
        },
        sort() {
            return {shipping_name: 1, created: 1}
        },
        numselected() {
            let e = this.update
            return Object.keys(this.selected).length
        },
        selectedA() {
            let la = []
            let sel = this.selected
            for (let _id in sel) {
                la.push(_id)
            }
            return la
        },
        listFields() {
            let lf = []
            let fields = this.$schema[this.table].fields
            for (let name in fields) {
                let field = fields[name]
                field.name = name
                if (field.list) {
                    lf.push(field)
                }
            }
            return lf
        },
    },
    methods: {
        formatValue(field, value) {
            let text = value
            if (field.type == 'time') {
                text = new Date(value * 1000).toLocaleDateString('hu-HU', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            } else if (field.type == 'checklist') {
                text = ''
                if (typeof value == 'object') {
                    for (let key in field.values) {
                        let vv = field.values[key]
                        if (value[key]) {
                            if (text != '') {
                                text += ', '
                            }
                            text += '<i class="text-xl las la-check-square"></i> ' + vv.title
                        }
                    }
                }
            }
            if (field.values && field.type != 'checklist') {
                let vv = field.values[value]
                if (vv) {
                    text = vv.title
                }
            }
            return text
        },
        async reload() {
            let projection = false
            let fields = this.$schema[this.table].fields
            for (let fid in fields) {
                let field = fields[fid]
                if (field.delist) {
                    if (!projection) {
                        projection = {}
                    }
                    projection[fid] = 0
                }
            }
            const res = await this.$store.API('admin/list', {
                table: this.table,
                filter: { _id: { $in: this.selectedA } },
                projection: projection,
                sort: this.sort
            })
            this.loading = false
            if (res) {
                this.list = res.list
                this.total = res.total
            } else {
                this.list = []
                this.total = 0
            }
        },
    },
}
