<template>
    <div class="p-2">
        <h1>Üdv, {{ $store.state.user.name }}!</h1>
        <br />
        <a @click="logout" class="text-blue-400 font-bold">Kilépés <i class="las la-sign-out-alt text-2xl align-middle"></i></a>
        <br />
        <button @click="themeSwitch()" class="mt-8 p-2 px-4 rounded-md text-white bg-blue-400">Váltás <span v-if="!isDark()">sötét</span> <span v-if="isDark()">világos</span> témára</button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                updated: 0,
            }
        },
        name: 'Home',
        components: {},
        methods: {
            logout() {
                this.$store.dispatch('user/logout')
                this.$router.push('/login')
            },
            themeSwitch() {
                document.documentElement.classList.toggle('dark')
                localStorage.theme = localStorage.theme === 'dark' ? 'light' : 'dark'
                this.updated++
            },
            isDark() {
                let u = this.updated
                return (localStorage.theme == 'dark')
            }
        },
    }
</script>
