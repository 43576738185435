const lat_spec =
  "¡¿ÄäÀàÁáÂâÃãÅåǍǎĄąĂăÆæĀāÇçĆćĈĉČčĎđĐďðÈèÉéÊêËëĚěĘęĖėĒēĜĝĢģĞğĤĥÌìÍíÎîÏïıĪīĮįĴĵĶķĹĺĻļŁłĽľĿŀÑñŃńŇňŅņÖöÒòÓóÔôÕõŐőØøŒœŔŕŘřẞßŚśŜŝŞşŠšȘșŤťŢţÞþȚțÜüÙùÚúÛûŰűŨũŲųŮůŪūŴŵÝýŸÿŶŷŹźŽžŻż"
const nonlat = RegExp("[^A-Za-z" + lat_spec + "]+", "gi")

const puncRight = /([\p{P}\p{S}])$/gu
const puncLeft = /^([\p{P}\p{S}])/gu
const puncAll = /([\p{P}\p{S}])/gu

function trimpunc(text) {
  text = "" + text
  let rpar = false
  if (text.indexOf(")") == text.length - 1) {
    rpar = true
  }
  text = text.replace(puncRight, "")
  text = text.replace(/([\s])$/gu, "")
  if (rpar) {
    text = text + ")"
  }
  return text
}

function unpunc(text) {
  text = text.replace(puncAll, "")
  return text
}

function pad(n, d = 2) {
  n = "" + n
  while (n.length < d) {
    n = "0" + n
  }
  return n
}

const pad2 = (n) => pad(n, 2)
const pad3 = (n) => pad(n, 3)

/* eslint-disable */
const validEmail = (email) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
/* eslint-enable */

function priceFormat(value, currency, language, country) {
  let mfd = 2
  if (currency == "HUF" || currency == "CZK") {
    mfd = 0
  }
  if (currency == "SVE7") {
    currency = "HUF"
  }
  let f = new Intl.NumberFormat(language + "-" + country, {
    style: "currency",
    minimumFractionDigits: mfd,
    maximumFractionDigits: mfd,
    currency: currency,
  }).format(parseFloat(value))
  f = f.replace(/EUR/gi, "€")
  f = f.replace(/RON/gi, "Lei")
  f = f.replace(/CZK/gi, "Kč")
  f = f.replace(/BGN/gi, "лв.")
  f = f.replace(/\,00\sLei/gi, " Lei")
  return f
}

function timeFormat(date, language, country) {
  return date.toLocaleDateString(language + "-" + country, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
}

function dateFormat(date, language, country) {
  return date.toLocaleDateString(language + "-" + country, {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })
}

function monogram(name, nameother = "XX") {
  if (!name) {
    name = nameother
  }
  name = "" + name
  if (name.length < 4) {
    name = nameother
  }
  if (!name) {
    name = "XX"
  }
  let mg = "XX"
  const etypes = [
    "CO",
    "PLC",
    "LLC",
    "LLP",
    "LTD",
    "GMBH",
    "AG",
    "KG",
    "KEG",
    "OHG",
    "OG",
    "OEG",
    "SE",
    "SCE",
    "EI",
    "SARL",
    "SÁRL",
    "SA",
    "SRO",
    "SCR",
    "FCR",
    "COOP",
    "AB",
    "HB",
    "KB",
    "EV",
    "EC",
    "EK",
    "BT",
    "KKT",
    "KFT",
    "KHT",
    "KV",
    "RT",
    "ZRT",
    "NYRT",
    "SZÖV",
    "ÉS",
    "TSA",
    "TÁRSA",
    "IRODA",
    "ÜGYVÉDI",
    "OE",
    "AE",
    "EE",
    "FIE",
    "UÜ",
    "TÜ",
    "OÜ",
    "AS",
    "MTÜ",
    "OYJ",
    "OSK",
    "PFA",
    "SRL",
    "SCA",
  ]
  let uname = name

  name = name.replace(puncAll, " ").toUpperCase().replace(/\s+/, " ").trim()
  let words = name.split(" ")
  let words2 = []
  words.forEach((w) => {
    w = w.trim()
    let inc = true
    if (w.length < 2) {
      inc = false
    }
    etypes.forEach((e) => {
      if (w == e) {
        inc = false
      }
    })
    if (inc) {
      words2.push(w)
    }
  })
  if (words2.length > 1) {
    mg = words2[0].substring(0, 1) + words2[1].substring(0, 1)
  } else if (words2.length == 1) {
    mg = words2[0].substring(0, 2)
  } else if (name.length >= 2) {
    mg = name.substring(0, 2)
  }
  return mg
}


const randomString = len => Array.from({length: len}, () => String.fromCharCode(Math.floor(Math.random() * 62) + (Math.floor(Math.random() * 2) ? 65 : 97))).join('');


export { pad, pad2, pad3, lat_spec, nonlat, puncAll, puncLeft, puncRight, trimpunc, unpunc, validEmail, priceFormat, timeFormat, dateFormat, monogram, randomString }
