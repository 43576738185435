<template>
  <div class="p-2">
    <h1>{{ title }}</h1>
    <router-link class="text-blue-500" :to="'/list/'+table+'/'"><i class="las la-arrow-left"></i> {{ $schema[table].title }}</router-link>
    <div v-if="loading" class="w-screen">
      <div class="text-primary w-32 h-32" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
        <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
        <path
            d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
        />
        </svg>
      </div>
    </div>
    <div v-if="!loading" class="text-right" :data-update="update">
      <Form :form="addForm()" @input="addInput" classes="w-1/3" :value="addValue" ref="addform" />
      <div class="w-1/3 pt-4">
        <button @click="submit()" class="bg-primary text-white font-bold p-4 px-8 rounded-md text-2xl"> Tovább  <i class="las la-angle-right"></i> </button>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/Select.vue'
import Form from '@/components/Form.vue'

export default {
  name: 'Add',
  components: {
    Select,
    Form,
  },
  data() {
        return {
          update: 0,
          loading: false,
          error: false,
          modify: {
            orders: {
              country: 'HU',
              language: 'hu',
              currency: 'HUF',
              payment_type: 'wiretransfer',
              courier: 'sameday',
            },
            users: {
              partner: true,
              shop: 'internetprint',
              country: 'HU',
              language: 'hu',
              discount: 20,
              user_discount: 10,
              affiliate_owner: this.generateRandomString(),
              affiliate_commission: 10,
              sales_commission: 10,
            },
          }
        }
  },
  
  computed: {
    table() { return this.$route.params.table },
    title() {
        return 'Új ' + this.$schema[this.table].title_single
    },
  },
  methods: {
    generateRandomString() {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },
    foptions(name) {
      let tfields = this.$schema[this.table].fields
      let options = [] 
      let vals = tfields[name].values
      for (let code in vals) {
        options.push({code: code, name: vals[code].title})
      }
      return options
    },
    addForm() {
      let fields, fieldsets, f = {} 
      if (this.table == 'orders') {
        fields = [
          { name: 'country', type: 'select', options: [
            {code: 'AT', name: 'Ausztria'},
            {code: 'CZ', name: 'Csehország'},
            {code: 'FR', name: 'Franciaország'},
            {code: 'HU', name: 'Magyarország'},
            {code: 'DE', name: 'Németország'},
            {code: 'IT', name: 'Olaszország'},
            {code: 'RO', name: 'Románia'},
            {code: 'SK', name: 'Szlovákia'},
          ], title: 'Ország:', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: 'language', type: 'select', options: [
            {code: 'en', name: 'angol'},
            {code: 'cs', name: 'cseh'},
            {code: 'fr', name: 'francia'},
            {code: 'hu', name: 'magyar'},
            {code: 'de', name: 'német'},
            {code: 'it', name: 'olasz'},
            {code: 'ro', name: 'román'},
            {code: 'sk', name: 'szlovák'},
          ], title: 'Nyelv:', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: 'currency', type: 'select', options: [
            {code: 'HUF', name: 'Magyar Forint (HUF)'},
            {code: 'EUR', name: 'Euró (EUR)'},
            {code: 'RON', name: 'Román Lei (RON)'},
          ], title: 'Pénznem', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: '_price', type: 'number', required: true, title: 'Érték (fenti pénznemben):', labelAlways: true, classes: 'justify-end', labelClasses: 'flex justify-between pb-2', help: "Tizedeseket ponttal kell jelezni"},
          { name: 'payment_type', type: 'select', options: this.foptions('payment_type'), title: 'Fizetési mód:', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: 'courier', type: 'select', options: this.foptions('courier'), title: 'Szállítási mód:', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},

          { name: 'contact_email', type: 'email', required: true, title: 'Kapcsolattartó email:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2', help: 'Megrendelő/Kapcsolattartó email címe. Ha nincs, új felhasználó jön létre.'},
          { name: 'contact_name', type: 'text', required: true, title: 'Kapcsolattartó neve:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2', help: 'Megrendelő/Kapcsolattartó neve, új felhasználó esetén.'},
          { name: 'contact_phone', type: 'text', required: true, title: 'Kapcsolattartó telefonszáma:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', help: 'Megrendelő/Kapcsolattartó telefonszáma, új felhasználó esetén.', inputClasses: 'w-1/2'},

          { name: 'invoice_note', type: 'text', title: 'Számla megjegyzés:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', help: 'Kapcsolódó rendelésszám, "korrekció" stb'},
          { name: '_product', type: 'text', required: true, title: 'Termék megnevezése, leírása:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: '_pcs', type: 'text', required: true, title: 'Termék(ek) mennyisége:', labelAlways: true, classes: 'justify-end', labelClasses: 'flex justify-between pb-2', help: 'Csak szám, db nem kell utána, többféle termék esetén Pl. 100+50'},
        ]
        fieldsets = [
          {name: 'add', fields: fields, classes: "w-full flex flex-col content-end text-right"}
        ]
        f = {
          fieldsets: fieldsets
        }
      } else if (this.table == 'users') {
        fields = [
          { name: 'name', type: 'text', required: true, title: 'Név:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: 'email', type: 'email', required: true, title: 'Email:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: 'phone', type: 'text', required: true, title: 'Telefonszám:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', inputClasses: 'w-3/5'},
          { name: 'affiliate_owner', type: 'text', required: true, title: 'Partner kód:', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', inputClasses: 'w-1/2'},

          { name: 'discount', type: 'number', required: true, title: 'Ügyfél (saját) kedvezménye (%):', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', inputClasses: 'w-1/5'},

          { name: 'user_discount', type: 'number', required: true, title: 'Partner ügyfelének kedvezménye (%):',labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', inputClasses: 'w-1/5'},
          { name: 'affiliate_commission', type: 'number', required: true, title: 'Partner jutaléka (%):', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', inputClasses: 'w-1/5'},
          { name: 'sales_commission', type: 'number', required: true, title: 'Sales jutaléka (%):', labelAlways: true, classes: 'justify-end', labelClasses: 'w-full flex justify-end pb-2', inputClasses: 'w-1/5'},
          
          { name: 'country', type: 'select', options: [
            {code: 'AT', name: 'Ausztria'},
            {code: 'CZ', name: 'Csehország'},
            {code: 'FR', name: 'Franciaország'},
            {code: 'HU', name: 'Magyarország'},
            {code: 'DE', name: 'Németország'},
            {code: 'IT', name: 'Olaszország'},
            {code: 'RO', name: 'Románia'},
            {code: 'SK', name: 'Szlovákia'},
          ], title: 'Ország:', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},
          { name: 'language', type: 'select', options: [
            {code: 'en', name: 'angol'},
            {code: 'cs', name: 'cseh'},
            {code: 'fr', name: 'francia'},
            {code: 'hu', name: 'magyar'},
            {code: 'de', name: 'német'},
            {code: 'it', name: 'olasz'},
            {code: 'ro', name: 'román'},
            {code: 'sk', name: 'szlovák'},
          ], title: 'Nyelv:', classes: 'justify-end', labelClasses: 'w-full flex justify-between pb-2'},

        ]
        fieldsets = [
          {name: 'add', fields: fields, classes: "w-full flex flex-col content-end text-right"}
        ]
        f = {
          fieldsets: fieldsets
        }
      }
      return f
    },
    addInput(e) {
      if (e.field == 'affiliate_owner') {
        e.value = (e.value).toUpperCase()
      }
      this.modify[this.table][e.field] = e.value
      if (e.field == 'contact_email' || e.field == 'email') {
        if (this.$validEmail(e.value)) {
          this.emailSearch(e.value)
        }
      }
      this.update++
    },
    addValue(fid) {
      return this.modify[this.table][fid]
    },
    async emailSearch(email) {
      const res = await this.$store.API('admin/list', {
        table: 'users',
        projection: {name: 1, phone: 1},
        filter: {email: email},
        limit: 1
      })
      if (res && res.list && res.list.length > 0) {
        let u = res.list[0]
        console.log("Email found", u)
        if (this.table == 'orders') {
          this.modify[this.table]['contact_name'] = u.name
          this.modify[this.table]['contact_phone'] = u.phone
        } else if (this.table == 'users') {
          this.$message('error', 'Ezzel az email címmel már létezik Ügyfél.')
        }
        this.update++
      }
    },
    async submit(e) {
      this.loading = true
      let m = this.modify[this.table]
      console.log("ADD", m)
      let data = {}
      for (let fid in m) {
        if (fid.indexOf('_') != 0) {
          let val = m[fid]
          data[fid] = val
        }
      }
      if (this.table == 'orders') {
        data.billing_country = m.country
        data.shipping_country = m.country

        data.items = [{
          settings: {
            product: 'other',
            shipping_free: true,
          },
          quote: {
            currency: m.currency,
            pcs: m._pcs,
            price: parseFloat(m._price),
            product_price: parseFloat(m._price),
            shipping_price: 0.,
            name: m._product,
          }
          
        }]
      } else if (this.table == 'users') {
        data = m
      }

      console.log('DATA', data)
      if (this.table == 'orders') {
        const res = await this.$store.API('account/order', {data: data})
        if (res) {
            this.modify = {}
            let _id = res.id1
            let code = res.code1
            this.$message('info', this.$schema[this.table].title_single + ' ' + code + ' létrehozva.')
            console.log('RES', res, _id)
            this.$router.push('/edit/'+this.table+'/'+_id)
        } else {
            this.$message('error', 'Művelet sikertelen!')
        }
      } else if (this.table == 'users') {
        const res = await this.$store.API('account/register', {data: data})
        if (res) {
          this.$message('info', this.$schema[this.table].title_single + ' létrehozva.')
          this.$router.push('/edit/'+this.table+'/'+res.user._id)
        } else {
          this.$message('error', 'Művelet sikertelen!')
        }
      }
      this.update++
      this.loading = false
    }
  }
}
</script>
