<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="p-2">
    <h1>{{ title }}</h1>
    <router-link class="text-blue-500" :to="'/list/'+table+'/'"><i class="las la-arrow-left"></i> {{ $schema[table].title }}</router-link>
    <br>
    <h2>{{ numselected }}db {{ $schema[table].title_single }} kiválasztva:</h2>
    <div v-if="loading" class="w-screen">
      <div class="text-primary w-32 h-32" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
        <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
        <path
            d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
        />
        </svg>
      </div>
    </div>
    <div v-if="!loading" style="user-select: text;">

      <table class="w-full border-gray-600 border-b-2">
        <tr class="font-bold border-gray-800 border-b-2 border-t-2">
          <td v-for="field in listFields" :key="field.name">
             <span v-html="field.title"></span>
          </td>
        </tr>
        <template v-for="(item, index) in list" :key="item._id">
          <tr :class="index % 2 ? 'bg-gray-300':''">
            <td v-for="field in listFields" :key="field.name">
              <span class="tabular-nums" v-html="formatValue(field,item[field.name])"></span>
            </td>
          </tr>
        </template>
      </table>

      <router-link v-if="!param && statuses.length == 0" :to="'/command/'+table+'/'+command+'/go/'"><div :class="'mt-8 p-2 px-8 rounded-md inline-block '+statusColor+' '+statusText">
        {{ title }}
      </div></router-link>

      <div v-if="!param && statuses.length > 0" class="mt-8">
        <router-link v-for="st in statuses" :key="st.command" :to="'/command/'+table+'/'+st.command+'/go/'"><div :class="'mt-2 mr-2 p-2 px-8 rounded-md inline-block '+st.bg+' '+st.text">
          {{ st.title }}
        </div></router-link>
      </div>

      <div v-if="buttons.length > 0" class="mt-8">
        <router-link v-for="bt in buttons" :key="bt.route" :to="bt.route"><div :class="'mt-2 mr-2 p-2 px-8 rounded-md inline-block '+(bt.bg?bt.bg:'bg-blue-800')+' '+(bt.text?bt.text:'text-white')">
          {{ bt.title }}
        </div></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Command from './command.js'

export default {
  name: 'Command',
  mixins: [Command],
  computed: {
    table() { return this.$route.params.table },
    command() { return this.$route.params.command },
    param() { return this.$route.params.param },
    param2() { return this.$route.params.param2 },
    statusTitle() { return this.$schema[this.table].fields.status.values[this.command].title },
    statusColor() {
      let sta = this.$schema[this.table].fields.status.values[this.command]
      if (sta) {
        return sta.color1
      } else {
        return 'blue-800'
      }
    },
    statusText() {
      let sta = this.$schema[this.table].fields.status.values[this.command]
      if (sta) {
        return sta.colort
      } else {
        return 'white'
      }
    },
    commandTitle() {
      let op = this.$schema[this.table].ops[this.command.replace('-','')]
      if (op) {
        return op.title
      } else {
        return '-'
      }
    },
    title() {
      if (!this.command) {return '-'}
        if (this.command.indexOf('-') == 0) {
          return this.commandTitle
        } else {
          return this.statusTitle
        }
    },
    statuses() {
      let op = this.$schema[this.table].ops[this.command.replace('-','')]
      if (op) {
        if (op.statuses) {
          let l = []
          op.statuses.forEach(s => {
            let sta = this.$schema[this.table].fields.status.values[s]
            let title = sta.title
            l.push({command: s, title: title, bg: sta.color1, text: sta.colort})
          })
          return l
        } else {
          return []
        }
      } else {
        return []
      }
    },
    buttons() {
      let l = []
      let op = this.$schema[this.table].ops[this.command.replace('-','')]
      if (op) {
        console.log('op',op)
        if (op.buttons) { l = op.buttons }
      }
      return l
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh()
    })
  },
  watch: {
    $route() {
        this.$nextTick(() => {
          this.refresh()
        })
    }
  },
  methods: {
    async refresh() {
      this.loading = true

      if (this.param == 'go') {
        let message = false
        let set = false

        if (this.command.indexOf('-') == 0) {
          if (this.command == '-invoice') {
            set = {flag_invoice: 1, payment_status: 'invoice_progress'}
            message = this.numselected +'db rendelés számlázás megkezdve.'
          } else if (this.command == '-download') {
            set = {flag_download: 1}
            message = this.numselected +'db rendelés letöltés megkezdve.'
          } else if (this.command == '-notify') {
            set = {}
            set['flag_' + this.param2] = 1
            message = this.numselected +'db rendelés értesítés megkezdve.'
          } else if (this.command == '-shipment') {
            let ts = ~~(Date.now() / 1000)
            if (this.param2 == 'tomorrow') {
              ts += 86400
            }
            if (this.param2 == 'datomorrow') {
              ts += 2 * 86400
            }
            set = {start_shipping: ts}
            message = this.numselected +'db rendelés küldés ideje beállítva.'
            if (this.param2 == 'gls' || this.param2 == 'packeta') {
              set = {courier: this.param2}
              message = this.numselected +'db rendelés szállítási mód beállítva: '+this.param2
            }
          }
        } else {
          message = this.numselected +'db ' + this.$schema[this.table].title_single + ' beállítva: ' + this.statusTitle
          set = {status: this.command, flag_status_changed: 1}
          if (this.command.indexOf('deleted') == 0) {
            set.flag_storno = 1
          }
        }
        if (set) {
          const res = await this.$store.API('admin/update', {
            set: set,
            table: this.table,
            filter: {_id: {$in: this.selectedA}},
          })
          if (res) {
            this.$store.commit('clearSelect', {
              table: this.table,
            })
            this.$message('info', message)
            const nres = await this.$store.NOTIFY()
            this.$router.push('/list/'+this.table+'/')
          } else {
            console.error(res)
          }
        }
        this.loading = false
      } else {
        await this.reload()
      }
      document.title = this.title
      this.update++
    },
    
  }
  
}
</script>
