<template>
    <div>
        <div v-if="loading" class="w-screen min-h-screen">
            <div class="text-primary w-32 h-32" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
                <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
                <path
                    d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
                />
                </svg>
            </div>
        </div>
        <div v-if="!loading" class="flex h-screen" id="login">
            <div class="m-auto xl:w-1/5 border-2 border-gray-700 flex flex-col shadow-2xl">
                <div class="bg-gray-700 text-gray-200 p-2">
                    <h1><img src="/img/icons/favicon.png" class="mr-1 h-8 inline" /> Bejelentkezés <i class="las la-key text-4xl align-middle"></i></h1>
                </div>
                <div v-if="message" class="p-2 px-4 font-bold text-red-400">
                    {{ message }}
                </div>
                <div class="p-2">
                    <Form :form="loginform" store="user" @submit="login" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Form from "@/components/Form.vue"

export default {
    name: 'Login',
    components: {
        Form
    },
    mounted() {
        this.$store.commit('user/updateField',{field: 'password', value: ''})
    },
    data() {
        return {
            loading: false,
            message: "",
            loginform: {
                fieldsets: [
                    {
                        name: "login",
                        fields: [
                            { name: "login_email", type: 'email', title: 'Email', autocomplete: "email", required: true},
                            { name: "login_password", type: 'password', title: 'Jelszó', inputClasses: 'submitenter', required: true},
                            { name: '-', html: '', classes: 'flex-grow'},
                            { name: 'submit', htmlClasses:'w-full text-right', html: '<button type="submit" class="bg-primary text-white mt-2 mr-1 px-6 py-3 text-xl text-white font-bold rounded-md"> Küld </button>'}
                        ]
                    }
                ]
            }
        }
    },
    methods: {
        login() {
            this.message = ""
            this.loading = true
            this.$store.dispatch('user/login').then((res) => {
                this.$store.commit('user/updateField',{field: 'login_password', value:""})
                this.loading = false
                if (res) {
                    this.$router.push('/list/orders')
                } else {
                    this.message = "Belépés megtagadva!"
                }
            })
        },
        logout() {
            this.$store.commit('user/logout')
            window.localStorage.removeItem('ipa')
            this.loading = true
            window.setTimeout(() => {
                this.$router.push('/login')
                window.location.reload()
            }, 1000)
        },
    },
}
</script>

<style>

</style>