<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="p-2 " :data-update="update">
    <div class="flex justify-between">
      <div class="text-3xl font-bold pb-2 text-primary">Lista: {{ title  }}</div>
      <div class="flex">
        <div v-for="op in gops" :key="op.route" class="p-0 px-2 font-bold text-green-700 dark:text-blue-400 text-3xl">
           <router-link :to="op.route" class="flex items-center"><i :class="'pr-1 text-3xl las la-'+op.icon+' text-'+op.color"></i> <span class="text-sm" v-html="op.title"></span></router-link>
        </div>
      </div>
    </div>
    <div class="w-full p-2 bg-gray-300 dark:bg-gray-700 rounded-md">
      <Form :form="filterForm" @input="filterInput" :value="filterValue" classes="flex flex-col xl:flex-row flex-wrap" @submit="submit" @enter="submit" />
      <div class="w-full flex justify-start gap-4">
        <button @click="submit" class="bg-primary text-white font-bold p-1 px-4 rounded-md text-2xl"> <i class="las la-filter"></i> Szűrés </button>
        <button @click="reset" class="border-gray-800 dark:border-gray-400 border-2 text-gray-800 dark:text-gray-500 font-bold p-1 px-4 rounded-md text-2xl"> <i class="las la-filter"></i> Beállítások törlése / Mindet mutat </button>
      </div>
    </div>
    
    <div v-if="loading" class="w-screen">
      <div class="text-primary w-32 h-32" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
        <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
        <path
            d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
        />
        </svg>
      </div>
    </div>
    <div v-if="!loading">

      <div class="w-full flex justify-between items-center p-2 pb-4">
        <div class="font-bold text-xl">{{ total }} találat.</div>
        <div class="flex items-center">
          <a @click="pageChange(-1)" v-if="skip > 0" class="rounded-md mx-1 p-1 px-2 bg-primary font-bold text-white flex items-center"><i class="las la-arrow-left"></i> Előző</a>
          <div class="font-bold p-2">{{ skip + 1 }}. -  {{ skip + Math.min(limit, list.length) }}. / {{ total }}</div>
          <a @click="pageChange(1)" v-if="skip < total - limit"  class="rounded-md mx-1 p-1 px-2 bg-primary font-bold text-white flex items-center">Következő <i class="las la-arrow-right"></i></a>
        </div>
        <div><Select :value="{code:limit, name:limit + ' / lap'}" :options="limitOptions" @input="limitChange($event)" classes="" /></div>
      </div>

      <div class="flex flex-wrap items-center bg-gray-200 dark:bg-gray-700" style="position: sticky;z-index: 100;top: 0;height: 60px;">
        <div :class="'mr-4 pl-4 pr-2 py-1 rounded-md font-bold text-white flex items-center '+(numselected>0?'bg-blue-600':'bg-gray-300 text-gray-400')">
          <span>{{ numselected }} kijelölve</span>
          <a @click="clearSelect()" v-if="numselected>0" class="ml-4 px-1 bg-blue-400 text-white rounded-full" title="Kiejölés törlése">
            <i class="text-xl las la-times"></i>
          </a>
        </div>
        <div v-for="op in ops" :key="op.route" class="p-0 px-2 font-bold text-green-800 dark:text-blue-400 text-2xl">
           <router-link v-if="numselected > 0" :to="op.route" class="flex items-center"><i :class="'pr-1 text-2xl las la-'+op.icon+' text-'+op.color"></i> <span class="text-sm" v-html="op.title"></span></router-link>
        </div>
      </div>

      <!-- TABLE -------------------------------------->
      <table class="w-full border-gray-600 border-b-2" style="user-select: text;">
        <tr class="font-bold bg-gray-200 dark:bg-gray-500 dark:text-blue-300" style="position: sticky;z-index: 100;top: 50px;border-top:2px solid #555; border-bottom:2px solid #555;">
          <td class="text-center border-gray-600 border-t-2 border-b-2" >
            <a @click="toggleSelectAll()" title="Mindent kijelöl (csak ezen a lapon!)"><i class="text-blue-600 p-1 px-3 rounded-md las la-check-double text-3xl"></i></a>
          </td>
          <td v-for="field in listFields" :key="field.name" :class="'border-gray-600 border-t-2 border-b-2 ' + (field.align=='right'?'text-right':'')">
             <a title="Rendezés"><span v-html="field.title"></span></a>
          </td>
        </tr>
        
        <template v-for="item in list" :key="item._id">
          <tr :class="'border-gray-400 border-t '+rowClass(item, item.index)">
            <td @click="toggleSelect(item._id)" class="text-center" :rowspan="detailRow?2:1">
              <input type="checkbox" :class="'selectcb ' + (detailRow ? 'big': 'small')" :checked="selected[item._id]">
            </td>
            <td v-for="field in listFields" :key="field.name" :class="field.align=='right'?'text-right':''">
              <router-link :to="'/edit/'+table+'/'+item._id"><span class="underline tabular-nums" v-html="formatValue(field,item[field.name])"></span></router-link>
            </td>
          </tr>

          <tr :class="rowClass(item, item.index)" v-if="detailRow && table=='orders'" :key="item._id+'dr'" :id="'record-'+item._id">
            <td :colspan="listFields.length">
              <div class="flex justify-start pb-2">
                <div class="flex flex-wrap w-1/4">
                  <a v-if="item.image1" @click="imageClick($config.api.cloud + 'static/share/' + item.token + '/' + item.image1 + '?g=' + item.generation)" class="w-1/2"><img :src="$config.api.cloud + 'static/share/' + item.token + '/' + item.image1 + '?g=' + item.generation" class="h-auto border border-gray-300" style="margin-right: 5px;"></a>
                  <a v-if="item.image2" @click="imageClick($config.api.cloud + 'static/share/' + item.token + '/' + item.image2 + '?g=' + item.generation)" class="w-1/2"><img :src="$config.api.cloud + 'static/share/' + item.token + '/' + item.image2+ '?g=' + item.generation" class="h-auto border border-gray-300"></a>
                  <a v-if="item.image_index" @click="imageClick(item.image_index.url + '?g=' + item.generation)"><img :src="item.image_index.pvUrl+ '?g=' + item.generation" width="250" class="mt-2 border border-gray-300"></a>
                  <div v-if="checkWrongYear(item)" class="text-white p-1 pl-4 mt-4 border border-white rounded font-bold text-xxl" style="background: red; flex-basis: 100%;">
                    Év: {{ item.item.settings.startYear }} Hónap: {{ item.item.settings.startMonth }}
                  </div>
                </div>
                <div class="pl-4" style="width: 300px;" v-if="item.item">
                  <b>{{ item.item.quote['pcs'] }} db {{ $productName(item) }}</b><br>
                  <div v-if="item.item.settings.coated && item.item.settings.coating == 'glossy'" class="m-1 p-1 px-4 w-min bg-blue-400 border border-white text-white font-bold uppercase">Fényes</div>
                  <div v-if="item.item.settings.coated && item.item.settings.coating == 'matte'" class="m-1 p-1 px-4 w-min bg-green-800 border border-white text-white font-bold uppercase">Matt</div>
                  <div v-if="item.item.settings.cornered" class="m-1 p-1 px-4 w-min bg-white border border-black text-black font-bold uppercase rounded-full">Sarok</div>
                  {{ $price(item.item.quote.product_price, item.item.quote.currency)}} + {{ $price(item.item.quote.shipping_price, item.item.quote.currency)}} =  <b class="text-xl">{{ $price(item.item.quote.price, item.item.quote.currency)}}</b><br>
                  <div v-if="item.item.quote.discountcode" class="p-1 bg-yellow-300 rounded text-black border border-gray-400">
                    Kuponkód: <b>{{ item.item.quote.discountcode }}</b> <b>({{ - item.item.quote.discount_percent }}%)</b>
                  </div>
                  <a :style="'padding: 2px 6px;'+smartListStyle(item.smart_list)" :title="' .    Lista ' + item.smart_list + ' (' + smartListDesc(item.smart_list) +')'" class="text-xs font-bold border-2 px-2 inline rounded-full">{{ item.smart_list }}</a>
                  <div class="inline text-xs ml-2">{{ smartProductDesc(item.smart_product) }}</div>
                  <br>
                  <br>
                  <div v-if="item.status=='new' || item.status=='printing'" class="w-min font-bold uppercase">
                    <a v-if="item.is_checked" class="py px-4 flex items-center rounded text-green-400" @click="toggleChecked(item)">
                      <i class="text-2xl las la-check"></i> Ellenőrizve
                    </a>
                    <a v-else class="py px-4 flex items-center rounded" style="background: #F00; color: white;" @click="toggleChecked(item)">
                      <i class="text-2xl las la-question"></i> Ellenőrizni!
                    </a>
                  </div>
                  <br>
                  <br>
                  <a v-if="item.pdf1" :href="$config.api.cloud + 'static/share/' + item.token + '/' + item.pdf1+ '?g=' + item.generation" target="_blank" class="text-blue-600 font-bold">
                    <span v-if="!item.pdf2"><i class="text-xl las la-file-pdf text-red-500"></i>Nyomtatható PDF</span>
                    <span v-if="item.pdf2 && item.product!='businesscard'"><i class="text-xl las la-file-pdf text-red-500"></i>Borító PDF</span>
                    <span v-if="item.pdf2 && item.product=='businesscard'"><i class="text-xl las la-file-pdf text-red-500"></i>A oldal PDF</span>
                  </a>
                  <a v-if="item.pdf2" :href="$config.api.cloud + 'static/share/' + item.token + '/' + item.pdf2+ '?g=' + item.generation" target="_blank" class="text-blue-600 font-bold">
                    <span v-if="item.product!='businesscard'"><i class="text-2xl las la-file-pdf text-red-500"></i>Belív PDF</span>
                    <span v-if="item.product=='businesscard'"><i class="text-2xl las la-file-pdf text-red-500"></i>B oldal PDF</span>
                  </a>
                  <a v-if="item.printable" :href="item.printable.url+ '?g=' + item.generation" target="_blank" class="text-blue-600 font-bold">
                    <span><i class="text-2xl las la-file-alt text-red-500"></i>Nyomtatható fájl</span>
                  </a>
                  <br>
                </div>
                <div class="pl-4" style="width: 300px;">
                  <b><a :href="'/edit/users/'+item.user._id">{{ $trans(item.contact_name) }} <i class="las la-user"></i></a></b><br>
                  Email: {{ item.contact_email }}<br>
                  Tel: <b>{{ item.contact_phone }}</b><br>
                  <button @click="emailFilter(item.user.email)" class="hover bg-gray-300 text-gray-800 p-1 px-2 rounded border border-gray-800"> Rendelései </button>
                  <a v-if="item.multiple_items" class="border border-purple-600 p-1 px-2 rounded-full bg-purple-600 text-white inline ml-2 text-xxl" title=" .     Több rendelés a csomagban"><i class="las la-boxes"></i></a>
                </div>

                <div class="pl-4">
                  <b>Szállítási cím:</b> <span v-html="$af(item, false) + ', ' + item.shipping_country"></span><br>
                  <b>Számlázási cím:</b> <span v-html="$af(item, true) + ', ' + item.billing_country"></span>

                  <div v-if="item.item.settings.product == 'other'">
                    <a @click="customLabel(item, 'normal')">
                      <span><i class="text-2xl las la-file-image text-green-500"></i>Normál címke</span>
                    </a>
                    <a @click="customLabel(item, 'wide')">
                      <span><i class="text-2xl las la-file-image text-green-500"></i>Széles címke</span>
                    </a>
                    <a @click="customLabel(item, 'extrawide')">
                      <span><i class="text-2xl las la-file-image text-green-500"></i>Extra széles címke</span>
                    </a>
                  </div>

                  <div v-if="item.flag_label > 0" class="py-2">
                    <span class="bg-red-700 uppercase text-gray-100 p-1 mb-2">Címkézés folyamatban...</span>
                  </div>

                  <div>
                    <div v-if="item.status_changed_sent" class="pr-4 whitespace-nowrap"><i class="text-xl las la-envelope"></i>(#1) Állapot változás értesítő (feldolgozás megkezdve): <b>{{ $time(new Date(item.status_changed_sent * 1000)) }}</b></div>
                    <div v-if="item.delivery_sent" class="pr-4 whitespace-nowrap"><i class="text-xl las la-envelope"></i><i class="text-xl las la-truck"></i> Szállítási/átvételi értesítő: <b>{{ $time(new Date(item.delivery_sent * 1000)) }}</b></div>
                    <div v-if="item.payrequest_sent" class="pr-4 whitespace-nowrap"><i class="text-xl las la-envelope"></i><i class="text-xl las la-money-bill"></i> Fizetési felszólítás: <b>{{ $time(new Date(item.payrequest_sent * 1000)) }}</b></div>
                    <div v-if="item.opinion_sent" class="pr-4 whitespace-nowrap"><i class="text-xl las la-envelope"></i>(?) Vélemény kérés: <b>{{ $time(new Date(item.opinion_sent * 1000)) }}</b></div>
                    <span v-if="item.trackingcode" class="whitespace-nowrap">
                      <br><i class="text-xl las la-truck"></i> Fuvarlevélszám: <b><a 
                      :href="trackingUrl(item)"
                      target="_blank" class="underline text-blue-600 mr-4">{{ item.trackingcode }}</a></b>
                    </span>
                    <a v-if="item.label_pdf" :href="$config.api.cloud + 'label/' + item.label_pdf" target="_blank" class="text-blue-600 font-bold pr-2">
                      <span><i class="text-xl las la-file-pdf text-red-500"></i>Címke</span>
                    </a>
                    <a v-if="item.label_bname" :href="$config.api.cloud + 'label/' + item.label_bname + '/' + item.label_bname + (item.courier == 'fan' ? '-fan':(item.courier == 'packeta' || item.courier == 'packetapoint' ? '-packeta':'')) + '.pdf'" target="_blank" class="text-blue-600 font-bold pr-2">
                      <span><i class="text-xl las la-file-pdf text-red-500"></i>Összes címke</span>
                    </a>
                    <span v-if="item.start_shipping > 0" class="mr-4">
                      <i class="text-xl las la-truck"></i> Küldés dátuma: <b>{{ $date(new Date(item.start_shipping * 1000)) }}</b>
                    </span>
                  </div>

                  <div v-if="item.note" class="border-2 border-black bg-white text-black m-1 p-2">
                      <b><i class="text-xl las la-pen"></i> Megjegyzés: </b>
                      <div v-html="(''+item.note).replaceAll('\n','<br>')"></div>
                  </div>

                  <div v-if="item.invoice_error" class="border-2 border-black text-white m-1 p-2" style="background: red;">
                      <b><i class="text-xl las la-alert"></i> Számlázási hiba: </b>
                      <div v-html="item.invoice_error"></div>
                  </div>

                  <div v-if="item.label_error" class="border-2 border-black text-white m-1 p-2" style="background: red;">
                      <b><i class="text-xl las la-alert"></i> Címkézési hiba: </b>
                      <div v-html="item.label_error"></div>
                  </div>

                  <div v-if="item.flag_invoices_download > 0 || item.flag_invoice > 0 || item.flag_storno > 0" class="py-2">
                    <span class="bg-blue-700 uppercase text-gray-100 p-1 mb-2">Számlázás folyamatban...</span>
                  </div>
                  <div v-else>
                    <div v-if="item.invoices && item.invoices.length > 0" class="py-2">
                      <div class="bg-blue-400 uppercase text-gray-100 p-2 mb-2 inline-block">Számlák:</div>
                      <div v-for="inv in item.invoices" :key="inv.bid" ><a :href="$config.api.cloud + 'invoice/' + item.token + '/' + inv.filename" target="_blank" class="text-blue-600">
                        <i class="text-xl las la-file-pdf text-blue-600"></i> <b>{{ inv.number }}</b>
                        <span v-if="inv.status=='active'" class="pl-2 text-green-700">(Érvényes)</span>
                        <span v-if="inv.status=='storno'" class="pl-2 text-red-700">(Sztornó)</span>
                        <span v-if="inv.status=='counter'" class="pl-2 text-orange-700">(Ellenszámla)</span>
                      </a></div>
                    </div>
                    <div v-else>
                      <div v-if="item.payment_status == 'paid'" class="text-white p-1 pl-4 border border-white rounded font-bold" style="background: green;"><i class="las la-money-bill-wave"></i> &nbsp; Sikeres fizetés! </div>
                      <div v-if="item.payment_status == 'unpaid' && (item.payment_type == 'wiretransfer' || item.payment_type == 'paypal' || item.payment_type == 'simple' || item.payment_type == 'stripe')" class="text-white p-1 pl-4 border border-white rounded font-bold" style="background: red;"><i class="las la-money-bill-wave"></i> &nbsp; Fizetésre vár! </div>
                    </div>
                  </div>

                  <button v-if="item.item.settings.product == 'other' && (item.status=='delivered' || item.status=='sent')" class="w-min rounded font-bold uppercase text-white p-1 px-4 " style="background: #fb923c;">
                    <a @click="reOrder(item)">
                      Utánrendelés <i class="text-2xl las la-shopping-cart text-yellow-200"></i>
                    </a>
                  </button>
                </div>

              </div>
            </td>
          </tr>

          <tr v-if="detailRow && table=='templates'" :key="item._id+'dr'" :id="'record-'+item._id">
            <td :colspan="listFields.length">
              <img :src="'https://static.internetprint.eu/preview/all/preview_' + item.name + '.jpg?g='+item.preview_generated" width="265">
            </td>
          </tr>
        </template>
      </table>
      <!-- TABLE -------------------------------------->

      <div class="flex items-center bg-gray-200 dark:bg-gray-700" style="position: sticky;z-index: 100;top: 0;height: 60px;">
        <div :class="'mr-4 pl-4 pr-2 py-1 rounded-md font-bold text-white flex items-center '+(numselected>0?'bg-blue-600':'bg-gray-300 text-gray-400')">
          <span>{{ numselected }} kijelölve</span>
          <a @click="clearSelect()" v-if="numselected>0" class="ml-4 px-1 bg-blue-400 text-white rounded-full" title="Kiejölés törlése">
            <i class="text-xl las la-times"></i>
          </a>
        </div>
        <div v-for="op in ops" :key="op.route" class="p-0 px-2 font-bold text-green-800 dark:text-blue-400 text-2xl">
           <router-link v-if="numselected > 0" :to="op.route" class="flex items-center"><i :class="'pr-1 text-2xl las la-'+op.icon+' text-'+op.color"></i> <span class="text-sm" v-html="op.title"></span></router-link>
        </div>
      </div>

      <div class="w-full flex justify-between items-center p-2">
        <div class="font-bold text-2xl">{{ total }} találat.</div>
        <div class="flex items-center">
          <a @click="pageChange(-1)" v-if="skip > 0" class="rounded-md mx-1 p-1 px-2 bg-primary font-bold text-white flex items-center"><i class="las la-arrow-left"></i> Előző</a>
          <div class="font-bold p-2">{{ skip + 1 }}. -  {{ skip + Math.min(limit, list.length) }}. / {{ total }}</div>
          <a @click="pageChange(1)" v-if="skip < total - limit"  class="rounded-md mx-1 p-1 px-2 bg-primary font-bold text-white flex items-center">Következő <i class="las la-arrow-right"></i></a>
        </div>
        <div><Select :value="{code:limit, name:limit + ' / lap'}" :options="limitOptions" @input="limitChange($event)" classes="" /></div>
      </div>

    </div>
    <Modal ref="modal_image" title="Kép" grey>
      <img v-if="zoomimage" class="w-full h-auto" :src="zoomimage" />
    </Modal>
  </div>
</template>

<script>

function wrapText(context, text, x, y, line_width, line_height) {
  var line = '';
  var paragraphs = text.split('\n');
  for (var i = 0; i < paragraphs.length; i++) {
    var words = paragraphs[i].split(' ');
    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + ' ';
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > line_width && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + ' ';
        y += line_height;
      }
      else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
    y += line_height;
    line = '';
  }
}

import Select from '@/components/Select.vue'
import Form from '@/components/Form.vue'
import Modal from '@/components/Modal.vue'
import { drawBarcode } from 'bardcode'
import { changeDpiDataUrl } from 'changedpi'
import LazyList from 'lazy-load-list/vue'

export default {
  name: 'List',
  components: {
    Select,
    Form,
    Modal,
    LazyList
  },
  data() {
        return {
          update: 0,
          loading: false,
          error: false,
          list: [],
          total: 0,
          zoomimage: false,
          checkRef: []
        }
  },
  computed: {
    table() { return this.$route.params.table },
    title() { return this.$schema[this.table].title},
    gops() {
      let ops = this.$schema[this.table].gops
      let access = this.$store.state.user.access
      if (access && access.restricted_ops && access.restricted_ops[this.table]) {
        let rops = access.restricted_ops[this.table]
        for (let key in rops) {
          delete ops[key]
        }
      }
      return ops
    },
    ops() {
      let ops = this.$schema[this.table].ops
      let access = this.$store.state.user.access
      if (access && access.restricted_ops && access.restricted_ops[this.table]) {
        let rops = access.restricted_ops[this.table]
        for (let key in rops) {
          delete ops[key]
        }
      }
      return ops
    },
    detailRow() { return this.$schema[this.table].detail_row},
    filter() { return  this.$store.state.tables[this.table].filter},
    limit() { return this.$store.state.tables[this.table].limit},
    sort() { return this.$store.state.tables[this.table].sort},
    skip() { return this.$store.state.tables[this.table].skip},
    selected() { return this.$store.state.tables[this.table].selected},
    numselected() { let e = this.update; return Object.keys(this.selected).length},
    listFields() {
      let lf = []
      let fields = this.$schema[this.table].fields
      for (let name in fields) {
        let field = fields[name]
        field.name = name
        if (field.list) {
          lf.push(field)
        }
      }
      return lf
    },
    limitOptions() {
      return [
        {code: 5, name: '5 / lap'},
        {code: 10, name: '10 / lap'},
        {code: 25, name: '25 / lap'},
        {code: 50, name: '50 / lap'},
        {code: 100, name: '100 / lap'},
        {code: 200, name: '200 / lap'},
      ]
    },
    filterForm()  {
      let e = this.update;
      let fieldsets = [
          {name: "group1", fields: [], classes: "w-1/3 flex flex-col content-end text-right"},
          {name: "group2", fields: [], classes: "w-1/3 flex flex-col content-end text-right"},
          {name: "group3", fields: [], classes: "w-1/3 flex flex-col content-end text-right"},
      ]

      let fields = this.$schema[this.table].fields
      for (let fid in fields) {
        let field = fields[fid]
        field.name = fid
        if (!field.type) {
          field.type = "text"
        }
        if (!field.filter_group) {
          field.filter_group = 1
        }
        if (field.filter) {
          let group = field.filter_group - 1
          if (field.type == "text" || field.type == "int" || field.type == "search" || field.type == "hidden") {
            let submitenter = true
            if (field.type == "hidden") {
              submitenter = false
            }
            fieldsets[group].fields.push({ name: field.name, type: "text", title: field.title+':', placeholder: field.placeholder, classes: 'p-0 pb-2 justify-end', inputClasses: field.classes + (submitenter ? ' submitenter' : ''), labelAlways: true, labelClasses:'flex p-0'})
          } else if (field.type == "select") {
            let maxw = 10
            let options = [{code: '-', name: '- MIND -'}]
            for (let code in field.values) {
              let opt = field.values[code]
              options.push({code: code, name: opt.title})
              if (opt.title.length > maxw) {
                maxw = opt.title.length
              }
            }
            fieldsets[group].fields.push({ name: field.name, options: options, type: "select", title: field.title+':', minWidth: maxw, classes: 'p-0 pb-1 justify-end', inputClasses: 'p-0 '+field.classes, labelClasses:'flex p-0'})
          } else if (field.type == "time") {
            fieldsets[group].fields.push({ name: field.name, type: "date", range: true, title: field.title+':', labelAlways: true, labelClasses: 'flex p-0 pb-2', classes: 'p-0 justify-end', inputClasses: field.classes})
          } else if (field.type == "checkbox") {
            fieldsets[group].fields.push({ name: field.name, type: "select", options: [{code: '-', name: '-'}, {code: 'true', name: 'Igen'}, {code: 'false', name: 'Nem'}], title: field.title+':', labelAlways: true, labelClasses: 'flex p-0 pb-2', classes: 'p-0 justify-end', inputClasses: field.classes})
          }
        }
      }
      
      let f = {
        fieldsets: fieldsets
      }
      return f
    }
  },
  mounted() {
    this.loading = true
    this.$nextTick(async () => {
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'filter',
        value: this.filterAccess(this.filter)
      })
      await this.refresh()
      window.addEventListener('hashchange',
        (e) => {
            this.hashChange()
        },
        false
      )
      this.hashChange()
      let sf = document.getElementById('-search-codes')
      if (sf) {
        sf.addEventListener('keypress', (e) => {
          console.log("SF KEYUP", e)
        })
      }
    })
  },
  watch: {
    $route() {
      this.loading = true
      this.$nextTick(() => {
        this.refresh()
      })
    }
  },
  methods: {
    hashChange() {
      let hash = window.location.hash
      if (hash) {
        hash = ('' + hash).replace('#', '').split('-')
        let command = hash[0]
        let record = hash[1]
        if (command && record) {
          console.log("HASH  command", command, record)
          if (command == 'scroll') {
            let el = document.getElementById('record-'+record)
            if (el) {
              const rect = el.getBoundingClientRect()
              const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
              let top = rect.top - viewHeight / 2
              //console.log("top:", top, "viewHeight:", viewHeight, "rect:", rect)
              window.scroll({
                top: top,
                behavior: 'smooth'
              })
            }
          }
        }
      }
    },
    async refresh() {
      this.loading = true
      this.list = []
      this.total = 0
      let projection = false
      let fields = this.$schema[this.table].fields
      for (let fid in fields) {
        let field = fields[fid]
        if (field.delist) {
          if (!projection) {
            projection = {}
          }
          projection[fid] = 0
        }
      }
      console.log("FILTER", this.filter)
      //console.log("PROJECTION", projection)
      const res = await this.$store.API('admin/list', {
        table: this.table,
        filter: this.filter,
        sort: this.sort,
        limit: this.limit,
        skip: this.skip,
        projection: projection
      })
      this.loading = false
      if (res) {
        let idx = 0
        this.list = res.list
        this.list.forEach(item => {
          item.index = idx++;
        })
        //console.log(this.list)
        this.total = res.total
      } else {
        this.list = []
        this.total = 0
      }
      document.title = this.title
      this.update++
    },
    formatValue(field, value) {
      if (typeof(field) == "string") {
        field = this.$schema[this.table].fields[field]
      }
      let text = value
      if (field.type == "time") {
        text = this.$time(new Date(value * 1000))
      } else if (field.type == "checklist") {
        text = ''
        if (typeof(value) == 'object') {
          for (let key in field.values) {
            let vv = field.values[key]
            if (value[key]) {
              if (text != '') {text += ', '}
              text += '<i class="text-xl las la-check-square"></i> '+vv.title
            }
          }
        }
      } else if (field.type == "checkbox") {
        if (''+value == 'true') {
          text ='<i class="text-xl las la-check-square"></i> Igen'
        } else if (''+value == 'false') {
          text = '-'
        }
      }
      if (field.values && field.type != "checklist") {
        let vv = field.values[value]
        if (vv) {
          text = vv.title
        }
      }
      return text
    },
    rowClass(item, index) {
      let c1 = 'bg-gray-100'
      let c2 = 'bg-gray-200'
      let ct = 'text-blue-800'
      if (item.status) {
        let sf = this.$schema[this.table].fields.status
        if (sf) {
          let s = sf.values[item.status]
          if (s) {
            if (s.color1) {
              c1 = s.color1
            }
            if (s.color2) {
              c2 = s.color2
            }
            if (s.colort) {
              ct = s.colort
            }
          }
        }
      }
      return (index % 2 ? c2 : c1) + ' ' + ct
    },
    trackingUrl(item) {
      let url = '#'
      if (item.courier == 'sameday'||item.courier == 'samedaylocker') {
        url = 'https://sameday.hu/#awb=' + item.trackingcode
      } else if (item.courier == 'packeta'||item.courier == 'packetapoint') {
        url =  'https://tracking.packeta.com/hu_HU/?id=' + item.trackingcode
      } else if (item.courier == 'fan') {
        if (item.trackingcode.indexOf('F') != -1) {
          url = 'https://fancourier.eu/track-order/#awb=' + item.trackingcode
        } else {
          url = 'https://www.fancourier.ro/en/awb-tracking/?awb=' + item.trackingcode
        }
      } else {
        url = 'https://gls-group.eu/HU/hu/csomagkovetes?match=' + item.trackingcode
      }
      return url
    },
    limitChange(e) {
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'limit',
        value: e.code
      })
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'skip',
        value: 0
      })
      this.refresh()
    },
    pageChange(e) {
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'skip',
        value: this.skip + e * this.limit
      })
      this.refresh()
    },
    addCheckRef(el) {
      if (el) {
        this.checkRef.push(el)
      }
    },
    toggleSelectAll(_id) {
      console.log("Toggle select all")
      this.list.forEach(item => {
        this.$store.commit('toggleSelect',{
          table: this.table,
          _id: item._id,
        })
      })
      this.update++
    },
    toggleSelect(_id) {
      this.$store.commit('toggleSelect',{
        table: this.table,
        _id: _id,
      })
      this.update++
    },
    clearSelect() {
      this.$store.commit('clearSelect',{
        table: this.table,
      })
      this.update++
    },
    filterInput(e) {
      this.$store.commit('filterSetting',{
        table: this.table,
        key: e.field,
        value: e.value
      })
      this.update++
    },
    filterValue(fid) {
      let field = this.$schema[this.table].fields[fid]
      let value = ''
      if (field) {
        value = this.$store.state.tables[this.table].filterForm[fid]
        if ((typeof value) == 'undefined') {
          if (field.type == "select") {
            value = '-'
          } else if (field.type == "date") {
            value = null
          }
        }
      }
      return value
    },
    filterAccess(filter = false) {
      if (!filter) {
        if (this.table == 'orders') {
          filter = { archive : 'alive' }
        }
      }
      let access = this.$store.state.user.access
      if (!access || !access.filter || !access.filter[this.table]) { return filter}
      let afilter = access.filter[this.table]
      for (let key in afilter) {
        filter[key] = afilter[key]
      }
      return filter
    },
    checkSearchField() {
      if (this.table != 'orders') {return}
      let ff = this.$store.state.tables[this.table].filterForm
      let sc = ff['-search-codes']
      if (!sc) {return}
      sc = (sc+'').trim()
      sc = sc.toUpperCase()
      sc = sc.replaceAll('Ö','0')
      sc = sc.replaceAll('ö','0')
      sc = sc.replaceAll('Ü','-')
      sc = sc.replaceAll('ü','-')
      sc = sc.replaceAll(' ','')
      if (sc.length == 10 && (sc.charAt(0) == '7' || sc.charAt(0) == '8')) {
        sc = sc.charAt(0) + sc.charAt(1) + sc.charAt(2) + sc.charAt(3) + '-' + sc.charAt(4) + sc.charAt(5) + sc.charAt(6) + '-' + sc.charAt(7) + sc.charAt(8) + sc.charAt(9)
      }
      if (sc=='') {return}
      console.log('Search codes:', sc)
      this.reset(false, false)
      this.$store.commit('filterSetting', {table: this.table, key: '-search-codes', value: ''})
      if (sc.length==12) {            
        this.$store.commit('filterSetting', {table: this.table, key: 'code', value: sc})
      } else {
        this.$store.commit('filterSetting', {table: this.table, key: 'trackingcode', value: sc})
      }
      this.update++
      let sf = document.getElementById('-search-codes')
      if (sf) {
        console.log(sf)
        sf.value = ''
      }
    },
    submit(e) {
      this.checkSearchField()
      let filter = {}
      let ff = this.$store.state.tables[this.table].filterForm
      for (let fid in ff) {
        let field = this.$schema[this.table].fields[fid]
        let value = ff[fid]
        if (value) {
          if (field.type == "text") {
            value = (''+value).trim()
            filter[fid] =  { $regex: value, $options: 'i' }
          } else if (field.type == "select") {
            if (value != "-") {
              filter[fid] = value
            }
          } else if (field.type == "int") {
              filter[fid] = parseInt(value)
          } else if (field.type == "time") {
            let subf = {}
            let df = false
            if (value['start']) {
              let tv = (value['start']).getTime()/1000
              //console.log(value['start'], tv)
              subf['$gte'] = tv
              df = true
            }
            if (value['end']) {
              let tv = (value['end']).getTime()/1000
              subf['$lte'] = tv
              //console.log(value['end'], tv)
              df = true
            }
            if (df) {
              filter[fid] = subf
            }
          } else if (field.type == "checkbox") {
            if (value == 'true') {
              filter[fid] = true
            } else if (value == 'false') {
              filter[fid] = false
            }
          }
        }
      }
      filter = this.filterAccess(filter)
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'filter',
        value: filter
      })
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'skip',
        value: 0
      })
      this.$store.commit('clearSelect',{
        table: this.table,
      })
      setTimeout(() => {
        this.refresh()
      }, 50)
    },
    emailFilter(email) {
      this.$store.commit('filterReset', {table: this.table})
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'filter',
        value: this.filterAccess()
      })
      this.$store.commit('filterSetting', {table: this.table, key: 'user.email', value: email})
      this.submit()
    },
    reset(e, doRefresh = true) {
      this.$store.commit('filterReset', {table: this.table})
      this.$store.commit('tableSetting',{
        table: this.table,
        key: 'filter',
        value: this.filterAccess()
      })
      if (doRefresh) {
        setTimeout(() => {
          this.refresh()
        }, 50)
      }
    },
    imageClick(src) {
      this.zoomimage = src
      this.$refs.modal_image.active = true
    },
    smartListDesc(n) {
      const lists = {
        '1': 'csak A3 naptár',
        '2': 'csak A4 naptár',
        '3': 'csak asztali naptár',
        '4': 'csak füzet',
        '5': 'csak könyv',
        '6': 'csak névjegy',
        '7': 'többféle termék, egyéb',
      }
      return lists[''+n] + ' a csomagban'
    },
    smartListStyle(n) {
      const colb = {
        '1': '#FF0000',
        '2': '#FFA500',
        '3': '#FFFF00',
        '4': '#008000',
        '5': '#0000FF',
        '6': '#4B0082',
        '7': '#ee82ee',
      }
      const colf = {
        '1': '#FFFFFF',
        '2': '#000000',
        '3': '#000000',
        '4': '#FFFFFF',
        '5': '#FFFFFF',
        '6': '#FFFFFF',
        '7': '#000000',
      }
      let colbn = colb[''+n]
      let colfn = colf[''+n]
      return `background:${colbn};color:${colfn};border-color:${colfn};`
    },
    smartProductDesc(product) {
      const ps = {
        'calendar_A3': 'A3',
        'calendar_A4': 'A4',
        'calendar_desktop': 'Asztali',
        'booklet': 'Füzet',
        'book': 'Könyv',
        'businesscard': 'Névjegy',
        'other': 'Egyéb',
        'rug': 'Szőnyeg',
        'invitation': 'Meghívó',
        'flyer': 'Szórólap'
      }
      return ps[product]
    },
    customLabel(item, type) {
      const sizes = {
        'normal': {
          w: 1840, h: 940, dpi: 497.2, size: 70, mrg: 70, bcx: 920, bcy: 730, bch: 150, mw: 7 
        },
        'wide': {
          w: 3200, h: 140, dpi: 271, size: 40, mrg: 50, bcx: 2700, bcy: 70, bch: 100, mw: 5
        },
        'extrawide': {
          w: 6496, h: 70, dpi: 550, size: 60, mrg: 50, bcx: 5600, bcy: 35, bch: 60, mw: 5
        },
      }
      let prop = sizes[type]
      let canvas = document.createElement('canvas')
      canvas.width = prop.w
      canvas.height = prop.h
      let ctx = canvas.getContext('2d')
      ctx.fillStyle = '#F00'
      ctx.fillRect(0,0, prop.w, prop.h)
      ctx.fillStyle = '#FFF'
      ctx.fillRect(3, 3, prop.w - 6, prop.h - 6)
      ctx.fillStyle = '#F00'
      ctx.font = prop.size + 'px arial'
      let ltext = `#${item.code}\n${item.item.quote.pcs}db ${item.item.quote.name} ${item.invoice_note ? ('('+item.invoice_note+')') : ''} ${item.contact_name}, Tel.: ${item.contact_phone}\nSzáll.: ${this.$af(item, false)}`
      if (type == 'extrawide') {
        ltext = ltext.replaceAll("\n",' ')
      }
      wrapText(ctx, ltext, prop.mrg, prop.mrg, prop.w - 2 * prop.mrg, prop.size)

      drawBarcode(ctx, item.code, {
        x: prop.bcx,
        y: prop.bcy,
        height: prop.bch,
        moduleWidth: prop.mw,
        horizontalAlign: 'center',
        verticalAlign: 'middle',
      })
      let anchor = document.createElement('a')
      let dataUrl = canvas.toDataURL('image/png')
      anchor.href = changeDpiDataUrl(dataUrl, sizes[type].dpi)
      anchor.download = item.code + '-label-' + type + '.png'
      anchor.click()
    },
    async reOrder(item) {
      this.loading = true
      let fdata = {
          table: this.table,
          filter: {
              _id: item._id,
          },
      }
      const res1 = await this.$store.API('admin/list', fdata)
      console.log("Original found", res1)
      if (res1 && res1.list.length > 0) {
          let data = res1.list[0]
          delete data._id
          delete data.token
          delete data.trackingcode
          delete data.note
          data.start_shipping = 0
          data.trackingcode = ''
          data.scanned = 0
          data.confirmation_sent = ~~(Date.now() / 1000)
          data.flag_notify = 0
          data.notified_sent = 0
          data.flag_notify_pay = 0
          data.notified_pay = 0
          data.payrequest_sent = 0
          data.flag_invoice = 0
          data.flag_storno = 0
          data.delivery_sent = 0
          data.flag_downloaded = 0
          data.invoices = []
          data.attachments = []
          data._history = []
          data._original = {}
          data.items = [data.item]
          data.invoice_note = ""//data.code + " utánrendelése"
          data.code = ''
          data.archive = 'alive'
          const res = await this.$store.API('account/order', {data: data})
          if (res) {
              console.log("New created", res)
              let _id = res.id1
              let code = res.code1
              this.$message('info', this.$schema[this.table].title_single + ' ' + code + ' létrehozva.')
              console.log('RES', res, _id)
              this.$router.push('/edit/'+this.table+'/'+_id)
          } else {
              this.$message('error', 'Művelet sikertelen!')
          }
          this.update++
      }
      this.loading = false
    },
    checkWrongYear(item) {
      if (item.product != 'calendar') { return false}
      let py = parseInt(item.item.settings.startYear)
      let pm = parseInt(item.item.settings.startMonth)
      let y =  new Date().getFullYear()
      let m = (new Date().getMonth()) + 1
      if (py < y) {
        return true
      }
      if (py == y && pm < m && m >= 8 ) {
        return true
      }
      return false
    },
    async toggleChecked(item) {
      item.wait_change = true
      let checked_state = !item.is_checked
      item.is_checked = checked_state
      let data = {
        table: this.table,
        filter: {
            _id: '_'+item._id,
        },
        set: {
          'is_checked': checked_state
        }
      }
      const res = await this.$store.API('admin/update', data)
      if (res) {
        this.$message('info', this.$schema[this.table].title_single + ' ' + item.code + ' módosítva.')
      } else {
        this.$message('error', 'Művelet sikertelen!')
      }
      item.wait_change = false
    }
  }
}
</script>

<style>
table input[type=checkbox] {
  opacity: 1;
  width: 2rem;
  height: 2rem;
  position: static;
}

table input[type=checkbox].small {
  margin-top: 0.3rem;
  width: 1.5rem;
  height: 1.5rem;
}

span.label-title {
  margin-top: 0.5em;
  margin-right: 0.5em;
}

</style>