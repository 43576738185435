import '@/assets/css/main.scss'
import { createApp } from 'vue'
//import Vue from 'vue'
import App from './App.vue'
import './unregisterServiceWorker'
import router from './router/router.js'
import store from './store/store.js'

const app = createApp(App)

const appConfig = require('./config.js')
const config = appConfig(process.env.NODE_ENV == "production")
app.config.globalProperties.$config = config

app.config.globalProperties.$schema = require('./schema.js')()

const ExcelJS = require('exceljs')
const iconv = require('iconv-lite');
//const { jsPDF } = require("jspdf"); 

import { lat_spec, trimpunc, unpunc, priceFormat, timeFormat, dateFormat, validEmail, monogram } from '../../mono/text.js'
import { formatAddress, addressFormat, isEU }  from '../../mono/print/AddressFormat.js'
import { productName, productNameShort, paymentType } from '../../mono/print/AdminUtils.js'
import { CountryStatus } from '../../mono/print/PrintQuote.js' 
import { transliterate } from 'transliteration'

// Setup I18n
import { createI18n } from 'vue-i18n'
//import VueI18n from 'vue-i18n'

transliterate.config({ ignore: lat_spec.split("") })

let lmessages = {
  'hu': {
    'not_found':'Nem található:',
    'select_or_search':'Válassz egyet, vagy keress!',
    'ok':'OK',
    'close': 'Bezár',
    'required': 'Kötelező',
  },
  'en': {
    'not_found':'Not found:',
    'select_or_search':'Select or search!',
    'ok':'OK',
    'close': 'Close',
    'required': 'Required',
  }
}

/*
const i18n = new VueI18n({
    locale: 'hu',
    messages: lmessages,
})
*/
const i18n = createI18n({
  locale: 'hu',
  messages: lmessages,
})
app.use(i18n)


app.config.globalProperties.$trim = function(text) {
  return trimpunc(text)
}

app.config.globalProperties.$unpunc = function(text) {
  return unpunc(text)
}

app.config.globalProperties.$tr = function(...args) {
  return trimpunc(this.$t(...args))
}

app.config.globalProperties.$time = function(date) {
  return timeFormat(date, 'hu', 'HU')
}

app.config.globalProperties.$date = function(date) {
  return dateFormat(date, 'hu', 'HU')
}

app.config.globalProperties.$price = function(price, currency) {
  return priceFormat(price, currency, 'hu', 'HU')
}

app.config.globalProperties.$af = function(data, billing=false) {
  return transliterate(formatAddress(data, billing))
}

app.config.globalProperties.$cs = function(country) {
  return CountryStatus(country)
}

app.config.globalProperties.$eu = function(country) {
  return isEU(country)
}

app.config.globalProperties.$productName = function(data) {
  return productName(data, 'hu')
}

app.config.globalProperties.$productNameShort = function(data) {
  return productNameShort(data, 'hu')
}

app.config.globalProperties.$paymentType = function(data) {
  return paymentType(data, 'hu')
}

app.config.globalProperties.$trans = function(text) {
  return transliterate(text)
}

app.config.globalProperties.$monogram = function(text) {
  return monogram(transliterate(text))
}

app.config.globalProperties.$iconv = iconv

app.config.globalProperties.$excel = function() {
  const workbook = new ExcelJS.Workbook()
  return workbook
}

/*
app.config.globalProperties.$pdf = function(options={}) {
  const doc = new jsPDF(options)
  return doc
}
*/

app.config.globalProperties.$message = function(level='info', message, details='', stay = 5000) {
  this.$store.commit('message', {
    level: level,
    text: message,
    details: details,
    stay: stay,
    hidden: false,
    at: ~~(Date.now())
  })
}

app.config.globalProperties.$validEmail = validEmail 

app.config.productionTip = false

app.use(router)
app.use(store)
app.mount('#app')

/*
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
*/
