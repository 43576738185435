<template>
  <div class="p-2">
    <h1>{{ title }}</h1>
    <router-link class="text-blue-500" :to="'/list/'+table+'/'"><i class="las la-arrow-left"></i> {{ $schema[table].title }}</router-link>
    <div v-if="loading" class="w-screen">
      <div class="text-primary w-32 h-32" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
        <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
        <path
            d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
        />
        </svg>
      </div>
    </div>
    <div v-if="!loading">
        Upload ... {{ command }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Upload',
  data() {
        return {
          update: 0,
          loading: false,
          error: false,
        }
  },
  computed: {
    table() { return this.$route.params.table },
    command() { return this.$route.params.command },
    title() {
        return "Feltöltés"
    },
  },
  methods: {
  }
}
</script>
