<template>
    <div>
    <div id="app" class="w-full h-full min-h-screen flex flex-col bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-200 h-full">
        <ul v-if="$store.state.user.authenticated" id="nav" class="w-full bg-gray-700 dark:bg-gray-600 text-gray-100 xl:static flex">
            <router-link to="/">
                <li class="flex flex-col items-center p-2">
                    <img src="/img/icons/favicon.png" class="w-12 xl:w-16" />
                </li>
            </router-link>
            <router-link v-for="table in tables" :to="'/list/' + table.name + '/'" :key="table.name">
                <li class="flex flex-col items-center p-2">
                    <i :class="'las la-' + table.icon + ' text-2xl xl:text-4xl align-middle'"></i>
                    <div class="text-xs uppercase">{{ table.title }}</div>
                </li>
            </router-link>
            <li class="flex-grow"></li>
            <li class="flex flex-col items-center p-2 overflow-hidden">
                <i :class="'las la-' + status_icon + ' text-' + status_color + ' text-2xl xl:text-4xl align-middle'"></i>
                <div class="text-xs uppercase">{{ status_name }}</div>
            </li>
        </ul>
        <div class="w-full h-max flex-grow">
            <router-view />
        </div>
    </div>
    <div class="fixed top-4 right-64" style="z-index: 99999;" :updated="updated">
        <div v-for="m in $store.state.messages" :key="m.at" :class="(m.level == 'error' ? 'bg-red-100 border-red-500 text-red-900': 'bg-blue-100 border-blue-500 text-blue-900') + ' border-t-4 rounded-b px-4 py-3 mb-4 shadow-md transition-all delay-900 animate-shake ' + (m.hidden ? 'opacity-0': 'opacity-100')" role="alert">
            <div class="flex">
                <div class="py-1 pr-4 ">
                    <i :class="'animate-bounce text-3xl las la-' + (m.level == 'error' ? 'exclamation-triangle':'info-circle')"></i>
                </div>
                <div class="flex-grow">
                    <p class="font-bold">{{ m.text }}</p>
                    <p class="text-sm">{{ m.details }}</p>
                </div>
                <div class="py-0 pl-1 ">
                    <a @click="$store.commit('deleteMessage', m.id)"><i class="text-sm las la-times"></i></a>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>

    export default {
        computed: {
            tables() {
                let tables = []
                for (let name in this.$schema) {
                    let table = this.$schema[name]
                    table.name = name
                    tables.push(table)
                }
                return tables
            },
        },
        data() {
            return {
                status_icon: 'check-circle',
                status_color: 'primary',
                status_name: 'Kész',
                updated: 0,
                statusi: 0,
                lastStatus: {},
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.setInterval(() => {
                    let ts = ~~(Date.now())
                    this.$store.state.messages.forEach(m => {
                        if (!m.hidden && (ts - m.at > m.stay - 1000)) {
                            this.$store.commit('hideMessage', m.id)
                            this.updated++
                        }
                        if (ts - m.at > m.stay) {
                            this.$store.commit('deleteMessage', m.id)
                            this.updated++
                        }
                    })
                }, 50)
                window.setInterval(() => {
                    this.statusCheck()
                }, 2457)
                this.statusCheck()
            })
        },
        methods: {
            async statusCheck() {
                const statuses = [
                    {code: '', title: 'Generálás'},
                    {code: '-photobook', title: 'FK. Generálás'},
                    //{code: '-label', title: 'Címkézés'},
                    //{code: '-invoice', title: 'Számlázás'},
                    //{code: '-confirmation', title: 'Értesítés'},
                    //{code: '-download', title: 'Letöltés'},
                ]
                try {
                    let st = statuses[this.statusi % statuses.length]
                    let resp = await this.$store.processAPI('status'+st.code+'.json')
                    //console.log("Status check", resp)
                    if (resp) {
                        resp.title = st.title
                        this.lastStatus[st.code] = resp
                    }

                    let allReady = true
                    let title = ''
                    for (let code in this.lastStatus) {
                        st = this.lastStatus[code]
                        if (!(st.code == 'ready' || st.code == 'finished')) {
                            if (title) { title += ' | '}
                            title += st.title + ': ' + (st.text?st.text : st.code+' Feldolgozás')
                            allReady = false
                        }
                    }
                    if (allReady) {
                        this.status_icon = 'check-circle'
                        this.status_color = 'primary'
                        this.status_name = 'Kész'
                    } else {
                        this.status_icon = 'hourglass'
                        this.status_color = 'yellow-500'
                        this.status_name = title
                    }
                } catch (e) {
                    console.error("STATUS CHECK", e)
                }
                this.statusi++
            }
        }
    }
</script>

<style></style>
