if (typeof window === 'undefined') {
    var window = {}
}

function productNameShort(o, language='hu') {
    const product_name = {
        businesscard: 'Névjegy',
        calendarA3: 'A3 Fali',
        calendarA4: 'A4 Fali',
        calendardesktop: 'Asztali',
        photobook: 'Könyv',
        photobooklet: 'Füzet',
        photoalbum: 'Album',
        rug: 'Szőnyeg',
    }
    const pb_format = {
        '15x15': '15x15cm',
        '20x20': '20x20cm',
        '20x29': 'A4',
        '29x29': 'ÓRIÁS',
    }
    let product = o.item.settings.product
    let format = o.item.settings.format
    let nPages = o.item.activePages

    let name = product + ' ' + format
    if (product == 'businesscard') {
        name = product_name[product] + ' ' + format + 'mm'
    } else if (product == 'photobook') {
        let pbpages = (nPages - 1) * 2
        let make = o.item.settings.make ? o.item.settings.make : (pbpages <= 40 ? 'booklet' : 'book')
        name = pbpages + 'o. ' + pb_format[format] + ' ' + product_name['photo' + make]
    } else if (product == 'calendar') {
        name = product_name[product+format]
    } else if (product == 'other') {
        name = o.item.quote.name
    } else {
        name = product_name[product] + ' ' + format + 'cm'
    }
    return name
}
window.productNameShort = productNameShort

function productName(o, language='hu') {
    const product_name = {
        businesscard: 'Névjegykártya',
        calendarA3: 'A3 Falinaptár',
        calendarA4: 'A4 Falinaptár',
        calendardesktop: 'Asztali naptár',
        photobook: 'Fotókönyv',
        photobooklet: 'Fotófüzet',
        photoalbum: 'Fotóalbum',
        rug: 'Szőnyeg',
    }
    const pb_format = {
        '15x15': '15x15cm',
        '20x20': '20x20cm',
        '20x29': 'A4',
        '29x29': 'ÓRIÁS',
    }
    let product = o.item.settings.product
    let format = o.item.settings.format
    let nPages = o.item.activePages

    let name = product + ' ' + format
    if (product == 'businesscard') {
        name = format + 'mm ' + product_name[product]
    } else if (product == 'photobook') {
        let pbpages = (nPages - 1) * 2
        let make = o.item.settings.make ? o.item.settings.make : (pbpages <= 40 ? 'booklet' : 'book')
        name =  pbpages + 'oldalas ' + pb_format[format] + ' ' + product_name['photo' + make]
    } else if (product == 'calendar') {
        name = product_name[product+format]
    } else if (product == 'other') {
        name = o.item.quote.name
    } else {
        name = format + 'cm '  + product_name[product]
    }
    return name
}
window.productName = productName

function paymentType(o, language='hu') {
    const ptypes = {
        cod: {title: 'Utánvét'}, // postpaid_regular volt
        wiretransfer: {title: 'Átutalás'},
        paypal: {title: 'Bankkártya (PayPal)'},
        skrill: {title: 'Bankkártya (Skrill)'}, // moneybookers volt
        stripe: {title: 'Bankkártya (Stripe)'},
        simple: {title: 'Bankkártya (SimplePay)'},
        barion: {title: 'Bankkártya (Barion)'},
        cash: {title: 'Készpénz (Telephely)'},
        cash_pos: {title: '* Bankkártya (Telephely)'},
        wiretransfer_office: {title: '* Átutalás (Telephely)'}, // office_wire volt
    }
    let ptype = o.payment_type
    ptype = ptypes[ptype]
    if (ptype) {
        return ptype.title
    } else {
        return "Egyéb"
    }
}
window.paymentType = paymentType


export { productName, productNameShort, paymentType }
