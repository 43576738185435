<template>
    <div>
        <div :class="{ modal: true, active: active, half: half }" @click="back = true">
            <div
                :class="{
                    'bg-white': true,
                    grey: grey,
                    'xl:rounded-lg': true,
                    'w-screen': true,
                    'h-screen': true,
                    'xl:h-auto': true,
                    'xl:min-h-3/4': true,
                    'xl:max-h-90': true,
                    'xl:w-11/12': true,
                    'shadow-lg': true,
                    flex: true,
                    'modal-box': true,
                }"
                @click="front = true"
            >
                <div class="w-full flex flex-col items-start p-2 xl:p-4">
                    <div v-if="!half" class="flex items-center w-full">
                        <button class="xl:hidden float-left empty p-2 pl-0 whitespace-nowrap" @click="close = true">
                            <Icon code="back" />
                        </button>
                        <div class="font-bold">{{ title }}</div>
                        <button class="hidden xl:block pr-0 empty whitespace-nowrap ml-auto" @click="close = true">
                            <Icon code="close" />
                        </button>
                        <button v-if="!confirm && !save" class="xl:hidden ml-auto success-text p-2 whitespace-nowrap" @click="ok = true">
                            <Icon code="check" /> <span class="text-xs">{{ $tr('ok') }}</span>
                        </button>
                        <button v-if="save" class="xl:hidden ml-auto bluish-text p-2 whitespace-nowrap" @click="$emit('save')">
                            <Icon code="save" /> <span class="text-xs">{{ $tr('save') }}</span>
                        </button>
                        <button v-if="confirm" class="xl:hidden ml-auto p-2 whitespace-nowrap" @click="close = true">
                            <Icon code="close" /> <span class="text-xs">{{ $tr('no') }}</span>
                        </button>
                    </div>

                    <div v-if="half" class="flex items-center w-full">
                        <div class="font-bold">{{ title }}</div>
                        <button class="hidden xl:block pr-0 empty whitespace-nowrap ml-auto" @click="close = true">
                            <Icon code="close" />
                        </button>
                        <button v-if="!confirm && !save" class="xl:hidden ml-auto success-text p-2 whitespace-nowrap" @click="ok = true">
                            <Icon code="check" /> <span class="text-xs">{{ $tr('ok') }}</span>
                        </button>
                        <button v-if="save" class="xl:hidden ml-auto bluish-text p-2 whitespace-nowrap" @click="$emit('save')">
                            <Icon code="save" /> <span class="text-xs">{{ $tr('save') }}</span>
                        </button>
                        <button v-if="confirm" class="xl:hidden ml-auto p-2 whitespace-nowrap" @click="close = true">
                            <Icon code="close" /> <span class="text-xs">{{ $tr('no') }}</span>
                        </button>
                    </div>

                    <div :class="'py-2 flex-grow xl:px-2 w-full h-full modal-content' + (scroll ? ' overflow-y-auto' : '')">
                        <slot></slot>
                    </div>

                    <div v-if="confirm && !half" class="w-full pt-4">
                        <button class="float-left text-alert" style="max-width: 70%;" @click="ok = true">{{ $tr('yes') + ', ' + commandTitle }} <Icon :code="commandIcon" /></button>
                        <button class="float-right primary" @click="close = true">{{ $tr('no') }} <Icon code="close" /></button>
                    </div>

                    <div v-if="!confirm && !save && !half" class="w-full pt-4 hidden xl:block">
                        <button class="float-left empty" @click="close = true">{{ $tr('close') }} <Icon code="close" /></button>
                        <button class="float-right primary" @click="ok = true">{{ $tr('ok') }} <Icon code="check" /></button>
                    </div>

                    <div v-if="save && !half" class="w-full pt-4 hidden xl:block">
                        <button class="float-right bluish" @click="$emit('save')">{{ $tr('save') }} <Icon code="save" /></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '@/components/Icon.vue'

    export default {
        name: 'Modal',
        components: {
            Icon,
        },
        props: {
            title: String,
            grey: Boolean,
            confirm: Boolean,
            command: { type: String },
            commandIcon: {type: String, default: 'trash'},
            save: Boolean,
            scroll: { type: Boolean, default: true },
            half: Boolean,
        },
        data() {
            return {
                id: Math.round(Math.random() * 1e9),
                active: false,
                close: false,
                front: false,
                back: false,
                ok: false,
                commandTitle: false
            }
        },
        mounted() {
            this.$nextTick(() => {
                setInterval(() => {
                    this.check()
                }, 50)
            })
        },
        methods: {
            check() {
                if (this.confirm) {
                    if (this.command) {
                        this.commandTitle = this.command
                    } else {
                        this.commandTitle = this.$t('delete')
                    }
                }
                let dbc = document.body.classList
                let idbo = dbc.contains('modal-open') && dbc.contains('modal-' + this.id)
                if (this.active) {
                    if (!idbo) {
                        dbc.add('modal-open')
                        dbc.add('modal-' + this.id)
                    }
                } else {
                    if (idbo) {
                        dbc.remove('modal-open')
                        dbc.remove('modal-' + this.id)
                    }
                }
                if (!this.active) {
                    return
                }
                if (this.close) {
                    this.clear()
                    this.active = false
                    return
                }
                if (this.ok) {
                    this.clear()
                    this.$emit('okpressed', 'ok')
                    //console.log("OK");
                    this.active = false
                    return
                }
                if (this.back && !this.front) {
                    this.active = false
                }
                this.clear()
            },
            clear() {
                this.close = false
                this.front = false
                this.back = false
                this.ok = false
            },
            closeOK() {
                this.ok = true
            },
            /*
    back() {
      console.log("back", event);
      this.back = true
    },
    front() {
      console.log("front", event);
      this.front = true
    },
    close(event) {
      console.log("closed", event);
      this.close = true
      //this.active = false;
    },
    ok() {
      console.log("ok", event);
      this.ok = true
      //this.active = false;
      //this.$emit("okpressed", "ok");
      //window.console.log("OK!!!");
    },
    */
        },
    }
</script>

<style>
    body.modal-open {
        overflow: hidden;
    }

    .modal {
        @apply flex items-center justify-center fixed left-0 top-0 w-full h-full;
        background: rgba(0, 0, 0, 0.8);
        opacity: 0;
        transition: opacity 0.25s, visibility 0.25s;
        visibility: hidden;
    }

    .modal.active {
        opacity: 1;
        visibility: visible;
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        height: -webkit-fill-available;
        width: 100vw;
    }


    .modal-box {
        transform: scale(1.3);
        transition: transform 0.25s;
        padding-bottom: 0.5rem;
    }

    .modal.active .modal-box {
        transform: scale(1);
    }

    .modal-content {
        max-height: calc(100vh - 4rem);
    }

    .modal.half {
        background: rgba(0, 0, 0, 0.4);
    }

    .modal.half .modal-box {
        transform: translate(0, 50vh) scale(1);
        transition: transform 0.3s;
        height: auto;
        position: fixed;
        top: auto;
        bottom: 0;
    }

    .modal.active.half .modal-box {
        transform: translate(0);
        height: auto;
        position: fixed;
        top: auto;
        bottom: 0;
    }

    .modal.half .modal-content {
        height: auto;
        max-height: calc(50vh - 4rem);
        @apply px-2 py-8;
    }

    @media (min-width: 1280px) {
        .modal-content {
            max-height: 75vh;
        }
    }
</style>
