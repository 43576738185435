<template>
  <div class="p-2">
    <h1>Beállítások</h1>
  </div>
</template>

<script>
export default {
  name: 'Settings',
}
</script>
