function isEU(country='') {
    return 'AT,BE,BG,CY,CZ,DK,EE,FI,FR,DE,GR,HU,IE,IT,LV,LT,LU,MT,NL,PL,PT,RO,SK,SI,ES,SE'.indexOf(country) >= 0
}


// https://gls-group.eu/HU/hu/rendszeres-csomagfeladas/hasznos-informacio
const isGLS = {
	HU: true, // I. zóna
	SK: true,
	AT: true,
	CZ: true,
	SI: true,
	RO: true,
	HR: true,

	DE: true, // II. zóna
	PL: true,
	BE: true,
	NL: true,
	LU: true,
	BG: true,

	IT: true, // III. zóna
	DK: true,
	FR: true,
	IE: true,

	ES: true, // IV. zóna
	PT: true,
	SE: true,
	FI: true,
	EE: true,
	LV: true,
	LT: true,

	//MT: true, // Malta
	//MC: true, // Monaco
	// görög?
}

function addressFormat(country='') {
    let fields = {
        name: {type: "text", required: true},
        iscompany: {},
        taxnumber: {required: true},
        vat: {},
        address1: {type: "text", required: true},
        address2: {type: "text"},
        postcode: {type: "text", required: true},
        city: {type: "text", required: true},
        region: {type: "text"},
        country: {type: "select"}
    }
    if ('DE,FR,GB,ES,UK,PL,RO,NL,PT,BG,CZ,HU,SV,AT,CH,RS,KV,DA,SK,FI,NO,BA,HR,MD,LT,AL,LV,MK,SI,CY,ME,LU,MT,IS,AD,LI,SM,MC,IN,ID'.indexOf(country) >= 0) {
        fields.region.type = "hidden"
    }
    if ('DE,IT,UK,PL,NL,GR,CZ,HU,SV,AT,CH,RS,KV,BG,DA,SK,FI,NO,BA,HR,MD,AL,MK,SI,CY,ME,MT,IS,LI,SM'.indexOf(country) >= 0) {
        fields.address2.type = "hidden"
    }
    if ('AG,AW,BS,VG,DM,GD,JM,MS,AN,KN,LC,VC,TC'.indexOf(country) >= 0) {
        fields.postcode.required = false
    }
    if (!isEU(country) || country == 'HU') {
        fields.vat.type = "hidden"
    }
    if (country != 'HU') {
        fields.taxnumber.type = "hidden"
        fields.taxnumber.required = false
    }
    return fields
}

function formatAddress(data, billing=false) {
    let flist = ['name','postcode','city','region','address1','address2','country']
    let language = data.language
    let bs = 'shipping_'
    let country = data.shipping_country
    if (billing) {
        country = data.billing_country
        bs = 'billing_'
        flist = ['name','taxnumber','vat','postcode','city','region','address1','address2','country']
    }
    let courier = data.courier

    let af = addressFormat(country)
    let fulla = ''
    flist.forEach(ae => {
        let incl = true
        let fi = af[ae]
        if (fi) {
            if (fi.type == 'hidden') {
                incl = false
            }
        }
        if (ae == 'country' && data.country == country) {
            incl = false
        }
        if (incl) {
            let v = data[bs+ae]
            if (v) {
                v = (''+v).trim()
                if (fulla != '') {
                    if (ae == 'city') {
                        fulla += ' '
                    } else {
                        fulla += ', '
                    }
                }
                if (ae == 'country' || ae == 'taxnumber' || ae == 'vat') {
                    fulla += '<b>'+v+'</b>'
                } else {
                    fulla += v
                }
            }
        }
    })
    if (courier == 'samedaylocker' && !billing) {
        if (data.locker) {
            fulla = `Csomagautomata #${data.locker.lockerId} ${data.locker.name}`
        } else {
            fulla = 'Csomagautomata (-NINCS KIVÁLASZTVA-)'
        }
    }
    if (courier == 'packetapoint' && !billing) {
        if (data.packetapoint) {
            fulla = `Packeta pont #${data.packetapoint.id} ${data.packetapoint.place} ${data.packetapoint.name}`
        } else {
            fulla = 'Packeta pont (-NINCS KIVÁLASZTVA-)'
        }
    }
    return fulla
}

export { isEU, isGLS, addressFormat, formatAddress }
