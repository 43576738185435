<!-- eslint-disable vue/no-v-for-template-key -->
<template>
    <form ref="form" @keydown="keyDown" :class="classes ? classes: ''" @submit="submit" :id="id">
        <template v-for="fieldset in form.fieldsets" :key="fieldset.name">
            <fieldset :class="fieldset.classes ? fieldset.classes : ''">
                <legend v-if="fieldset.title">{{ fieldset.title }}</legend>
                <div v-for="field in fieldset.fields" :key="field.name" :class="'input '+'field-'+field.name+aid+(field.classes ? ' '+field.classes : '')">
                    <label v-if="field.type=='text'||field.type=='number'||field.type=='email'||field.type=='password'" :class="(field.labelAlways?' ':'conditional ')+(field.labelClasses?field.labelClasses:'')">
                        <Icon v-if="field.icon" :code="field.icon" sm classes="mr-2" />
                        <span class="label-title" v-html="field.title"></span>
                        <span class="label-limit" v-if="field.limit" v-html="getLimit(field)"></span>
                        <input
                            :type="field.type"
                            :id="field.name+aid"
                            :name="field.name+aid"
                            :placeholder="field.placeholder ? field.placeholder : field.title"
                            :required="field.required"
                            :minlength="field.minlength?field.minlength:null"
                            :data-validation="field.validation"
                            :autocomplete="field.autocomplete"
                            @focus="keepOnScreen($event.target)"
                            :value="getField(field)"
                            @input="updateField(field,$event.target)"
                            @change="updateField(field,$event.target)"
                            v-on:invalid="invalid($event.target)"
                            :class="field.inputClasses"
                            :disabled="field.readonly"
                        >
                        <span v-if="field.help">
                            <!--<Popper
                                :options="{
                                    placement: 'top',
                                    modifiers: {
                                        offset: { offset: '0,10px' },
                                        preventOverflow: { boundary: 'document'}
                                    }
                                }">
                                <div v-html="field.help" class="popper p-2">
                                </div>
                                
                                <div slot="reference">
                                    <Icon :id="'fieldhelp-'+field.name+aid" code="info" sm classes="ml-2" />
                                </div>
                            </Popper> -->
                        </span>
                    </label>
                    <label v-if="field.type=='textarea'" :class="(field.labelAlways?' ':'conditional ')+(field.labelClasses?field.labelClasses:'')"><span class="label-title" v-html="field.title"></span><span></span>
                        <textarea
                            :type="field.type"
                            :id="field.name+aid"
                            :name="field.name+aid"
                            :rows="field.rows ? field.rows : 5"
                            :placeholder="field.placeholder ? field.placeholder : field.title"
                            :required="field.required"
                            :data-validation="field.validation"
                            :autocomplete="field.autocomplete"
                            @focus="keepOnScreen($event.target)"
                            v-html="getField(field)"
                            @input="updateField(field,$event.target)"
                            @change="updateField(field,$event.target)"
                            v-on:invalid="invalid($event.target)"
                            :class="field.inputClasses"
                        ></textarea>
                    </label>
                    <label v-if="field.type=='range'" :class="(field.labelAlways?' ':'conditional ')+(field.labelClasses?field.labelClasses:'')"><span class="label-title" v-html="field.title"></span><span></span>
                        <input
                            :type="field.type"
                            :min="field.min"
                            :max="field.max"
                            :step="field.step"
                            :id="field.name+aid"
                            :name="field.name+aid"
                            :placeholder="field.placeholder ? field.placeholder : field.title"
                            :required="field.required"
                            :data-validation="field.validation"
                            :autocomplete="field.autocomplete"
                            :value="parseFloat(getField(field))"
                            @focus="keepOnScreen($event.target)"
                            v-html="getField(field)"
                            @input="updateField(field,$event.target)"
                            v-on:invalid="invalid($event.target)"
                            :class="'slider '+field.inputClasses"
                        />
                    </label>
                    <div v-if="field.type=='date'" class="">
                        <label :class="(field.labelAlways?' ':'conditional ')+(field.labelClasses?field.labelClasses:'')"><span class="label-title" v-html="field.title"></span><span></span>
                        <DatePicker
                            :modelValue="getFieldDate(field)"
                            @update:modelValue="updateFieldDate(field, $event)"
                            @drag="updateFieldDate(field, $event)"
                            :masks="date.masks"
                            :popover="date.popover"
                            :locale="$i18n.locale"
                            :first-day-of-week="2"
                            :is-range="field.range"
                            is24hr
                            :input-debounce="600"
                            :model-config="date.modelConfig"
                            :attributes="date.attrs"
                        >
                            <template v-if="!field.range" v-slot="{ inputValue, inputEvents }">
                            <input
                                :class="field.inputClasses"
                                :value="inputValue"
                                v-on="inputEvents"
                            />
                            </template>

                            <template v-else v-slot="{ inputValue, inputEvents, isDragging }">
                                <div class="flex flex-col sm:flex-row justify-start items-center">
                                    <div class="relative flex-grow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        class="ml-2 mt-1 w-4 h-5 fill-current absolute pointer-events-none"
                                    >
                                        <path
                                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                        ></path>
                                    </svg>
                                    <input
                                        :class="'flex-grow pl-8 pr-2 ' + field.inputClasses + ' ' + (isDragging ? 'text-gray-600' : 'text-gray-900')"
                                        :value="inputValue.start"
                                        v-on="inputEvents.start"
                                    />
                                    </div>
                                    <span class="flex-shrink-0 m-2">
                                    <svg
                                        class="w-4 h-4 stroke-current text-gray-600"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                                        />
                                    </svg>
                                    </span>
                                    <div class="relative flex-grow">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        class="ml-2 mt-1 w-4 h-5 fill-current absolute pointer-events-none"
                                    >
                                        <path
                                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                        ></path>
                                    </svg>
                                    <input
                                        :class="'flex-grow pl-8 pr-2 ' + field.inputClasses + ' ' + (isDragging ? 'text-gray-600' : 'text-gray-900')"
                                        :value="inputValue.end"
                                        v-on="inputEvents.end"
                                    />
                                    </div>
                                </div>
                                </template>

                        </DatePicker>
                        </label>
                    </div>
                    <template v-if="field.type=='radio'">
                        <label v-if="field.title" :class="field.labelClasses?field.labelClasses:''"><span class="label-title" v-html="field.title"></span></label>
                        <div v-for="option in field.options"
                            :key="option.code"
                            :class="'cursor-pointer '+(getRadioChecked(field, option.code) ? 'checked ':'')+field.optionClasses"
                            @click="updateFieldRadio(field, option.code)"
                        >
                            <input  
                                type="radio"
                                :id="field.name+'-'+option.code+aid"
                                :name="field.name+aid"
                                :value="option.code"
                                :required="field.required"
                                :autocomplete="field.autocomplete"
                                :checked="getRadioChecked(field, option.code)"
                                @input="updateFieldRadio(field, option.code)"
                                @focus="keepOnScreen($event.target)"
                                :class="field.inputClasses"
                            />
                            <label :for="field.name+'-'+option.code+aid" :classes="(option.classes ? option.classes : '')"><Icon v-if="option.icon" :code="option.icon" sm classes="mr-1 "/>
                                <span v-html="option.name"></span>
                                <div v-if="option.image" class="pt-2">
                                    <img :src="option.image">
                                </div>
                            </label>
                        </div>
                    </template>
                    <div v-if="field.type=='checkbox'"
                        :class="'field-checkbox ' + field.optionClasses"
                    >
                        <input  
                            type="checkbox"
                            :id="field.name+aid"
                            :name="field.name+aid"
                            value="true"
                            :required="field.required"
                            :checked="getChecked(field)"
                            @input="updateFieldCheckbox(field, $event.target)"
                            @focus="keepOnScreen($event.target)"
                            v-on:invalid="invalid($event.target)"
                            :class="field.inputClasses"
                        />
                        <label :for="field.name+aid" :class="field.labelClasses?field.labelClasses:''"><span class="label-title" v-html="field.title"></span></label>
                    </div>
                    <template v-if="field.type=='checklist'">
                        <label v-if="field.title" :class="field.labelClasses?field.labelClasses:''" v-html="field.title"></label>
                        <div v-for="option in field.options"
                            :key="option.code"
                            :class="field.optionClasses"
                        >
                            <input  
                                type="checkbox"
                                :id="option.code+aid"
                                :name="option.code+aid"
                                value="true"
                                :required="option.required"
                                :checked="getChecked(option.code)"
                                @input="updateFieldCheckbox(option.code, $event.target)"
                                @focus="keepOnScreen($event.target)"
                                v-on:invalid="invalid($event.target)"
                                :class="field.inputClasses"
                            />
                            <label :for="option.code+aid" :class="field.labelClasses?field.labelClasses:''"><span class="label-title" v-html="option.name"></span></label>
                        </div>
                    </template>
                    <template v-if="field.type=='select'">
                        <div :id="'input-'+field.name+aid" :class="field.classes">
                            <label :class="field.labelClasses?field.labelClasses:''"><span class="label-title" v-html="field.title"></span>
                            <Select
                                :options="field.options"
                                @input="updateFieldSelect(field, $event)"
                                :value="getFieldSelect(field, field.options)"
                                :searchable="field.searchable"
                                :flags="field.flags"
                                :classes="field.inputClasses"
                                :minwidth="field.minWidth ? field.minWidth : 0"
                            />
                            </label>
                        </div>
                    </template>
                    <template v-if="field.type=='hidden'">
                        <input
                            :type="field.type"
                            :id="field.name+aid"
                            :name="field.name+aid"
                            :value="getField(field)"
                        />
                    </template>
                    <template v-if="field.type=='button'">
                        <div :id="'input-'+field.name+aid" :class="field.classes">
                            <button
                                :id="field.name+aid"
                                :name="field.name+aid"
                                :classes="field.inputClasses"
                                @click="$emit('buttonclick', field)"
                            >
                                <span class="label-title" v-html="field.title"></span>
                            </button>
                        </div>
                    </template>
                    <div v-if="field.html" :class="field.htmlClasses"  v-html="field.html"></div>
                </div>
            </fieldset>
        </template>
        <!-- {{ updated }} -->
        <button type="submit" ref="request_submit" style="display:none"></button>
    </form>
</template>

<script>
import Icon from '@/components/Icon.vue'
import Select from '@/components/Select.vue'
import { Calendar, DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css';
//import Popper from 'vue-popperjs';


export default {
  name: 'Form',
  components: {
    Icon,
    Select,
    Calendar,
    DatePicker,
    //Popper
  },
  props: {
      'form':Object,
      'classes':String,
      'store':String, // if false, emit @input
      'value':{ type: Function },
      'id':String,
      'aid': {type: String, default: ''}, // add to field names -s to make them unique
      'advanceEnter': {type: Boolean, default: true}
    },
  data: () => ({
      date: {
        masks: {
            input: 'YYYY.MM.DD',
        },
        popover: {
            visibility: "focus"
        },
        modelConfig: {
            timeAdjust: '00:00:00',
            start: {
                timeAdjust: '00:00:00',
            },
            end: {
                timeAdjust: '23:59:59',
            },
        },
        attrs: [
            {
                bar: 'red',
                dates: {
                    weekdays: [1, 7]
                }
            },
            {
                bar: 'green',
                dates: Date()
            },
        ]
      },
      updated: 0
  }),
  methods: {
    getField(field) {
        let name = field.name
        if (this.store) {
            return this.$store.state[this.store][name]
        } else {
            if (this.value) {
                return this.value(name)
            } else {
                return ''
            }
        }
    },
    updateField(field, target) {
        let name = field.name
        let value = target.value
        if (field.trim) {
            let value2 = value.replace(/\s+$/,' ').trimStart()
            if (value2 != value) {
                value = value2
                target.value = value
            }
        }
        if (field.limit) {
            if (value.length > field.limit) {
                value = value.substring(0, field.limit)
                target.value = value
            }
        }
        let mut
        if (this.store) {
            mut = this.store+'/updateField'
            this.$store.commit(mut, {field:name, value:value})
        } else {
            this.$emit('input', {field: name, value:value})
        }
        this.setValid(target)
        this.updated++
    },
    getFieldSelect(field, options) {
        let name = field.name
        if (this.store) {
            let code = this.$store.state[this.store][name]
            let sopt = this.findOption(code, options)
            return sopt
        } else {
            if (this.value) {
                let code = this.value(name)
                let sopt = this.findOption(code, options)
                return sopt
            } else {
                return {code: '-', name: '---'}
            }
        }
    },
    updateFieldSelect(field, value) {
        let name = field.name
        //console.log('updateFieldSelect', field, value)
        let mut
        if (this.store) {
            mut = this.store+'/updateField'
            this.$store.commit(mut, {field:name, value:value.code})
        } else {
            this.$emit('input', {field: name, value:value.code})
        }
        //this.setValid(target)
    },
    getChecked(field, code) {
        let name = field
        if (typeof(field) == 'object') {
            name = field.name
        }
        if (this.store) {
            return this.$store.state[this.store][name] ? 'checked' : false
        } else {
            if (this.value) {
                return this.value(name) ? 'checked' : false
            } else {
                return false
            }
        }
    },
    updateFieldCheckbox(field, target) {
        let name = field
        if (typeof(field) == 'object') {
            name = field.name
        }
        let checked = target.checked
        //console.log('updateFieldCheckbox', field, target, checked)
        let mut
        if (this.store) {
            mut = this.store+'/updateField'
            this.$store.commit(mut, {field:name, value:checked})
        } else {
            this.$emit('input', {field: name, value:checked})
        }
        if (field.required) {
            this.setValid(target)
        }
    },
    getRadioChecked(field, code) {
        let name = field.name
        if (this.store) {
            return this.$store.state[this.store][name] == code ? 'checked' : false
        } else {
            if (this.value) {
                return this.value(name) == code ? 'checked' : false
            } else {
                return false
            }
        }
    },
    updateFieldRadio(field, code) {
        let name = field.name
        let mut
        if (this.store) {
            mut = this.store+'/updateField'
            this.$store.commit(mut, {field:name, value:code})
        } else {
            this.$emit('input', {field: name, value:code})
        }
    },
    getFieldDate(field) {
        let name = field.name
        if (this.store) {
            return this.$store.state[this.store][name]
        } else {
            if (this.value) {
                return this.value(name)
            } else {
                return ''
            }
        }
    },
    updateFieldDate(field, value) {
        let name = field.name+this.aid
        let mut
        if (this.store) {
            mut = this.store+'/updateField'
            this.$store.commit(mut, {field:name, value:value})
        } else {
            this.$emit('input', {field: name, value:value})
        }
    },
    findOption(code, options) {
        let noval = {code :'-', name: '----'}
        if (!options || !code) {
            return noval
        }
        for (let fkey in options) {
            let fopt = options[fkey]
            if (''+fopt.code == ''+code) {
                return fopt
            }
        }
        return noval
    },
    requestSubmit() {
        this.$refs.request_submit.click()
    },
    reset () {
        this.$refs.form.reset()
    },
    submit(e) {
        this.$emit('submit')
        e.preventDefault()
        return false
    },
    formSubmit(e) {
        this.$refs.form.submit()
    },
    setValid(el) {
        el.setCustomValidity('')
        el.classList.remove('invalid')
        el.parentNode.classList.remove('invalid')
    },
    invalid(el) {
        let title = el.placeholder ? ': '+el.placeholder  : ''
        el.setCustomValidity(this.$tr('required')+title)
        el.classList.add('invalid')
        el.parentNode.classList.add('invalid')
    },
    keyDown(e) {
      if (event.keyCode === 13) {
        this.$emit('enter')
      }
      if (event.keyCode === 13 && this.advanceEnter) {
        let ac = document.activeElement;
        if (ac) {
          if (ac.tagName == "INPUT" && !ac.classList.contains('submitenter')) {
            e.preventDefault();
            const inputs = Array.prototype.slice.call(this.$refs.form.querySelectorAll("input,textarea,select"));
            const index = (inputs.indexOf(ac) + 1) % inputs.length;
            const input = inputs[index];
            input.focus();
            if (input.select) {
              input.select();
            }
          }
        }
      }
    },
    keepOnScreen(inp) {
        /*
      window.setTimeout(() => {
        let cr = inp.getClientRects()[0];
        let height = window.innerHeight;
        if (cr.top > height || cr.top < 75) {
          inp.scrollIntoView({
            block: "center",
          });
        }
      }, 300);
      */
    },
    getLimit(field) {
        let v = ''+this.getField(field)
        let len = v.length
        let ret = ' ('+len+'/'+field.limit+')'
        if (len > field.limit - 1) { ret = '<span style="color:red;">'+ret+'</span>'}
        return ret
    },
  }
}
</script>

<style lang="scss">
@use 'sass:math';
$xl: 1280px;
/*
--focus-color
--alert-color
--asterisk
*/

.vc-day-content {
    padding: 4px;
}

/*
form {
}

*/
legend {
    @apply font-bold text-xl p-1 pt-2;
    color: var(--focus-color);
}

input[type="text"], textarea {
    user-select: text;
}

.w-full > label {
    width: 100%;
}

label {
    @apply uppercase block text-sm font-bold px-1;
}

label.conditional {
    font-size: 0.5rem;
    line-height: 0.8rem;
    margin-top: -0.7em;
}

label.conditional span {
    @apply pl-2 opacity-0;
    font-size: 0.5rem;
    line-height: 0.8rem;
    visibility: hidden;
    position: relative;
    top: 1.5em;
    transition: visibility ease-in-out 400ms, opacity ease-in-out 400ms, color ease-in-out 400ms;
}

label.conditional:focus-within span {
    color: var(--focus-color);
    visibility: visible;
    @apply opacity-100;
}

label.conditional.invalid:focus-within span {
    color: var(--alert-color);
}

.input {
    @apply flex flex-wrap;
}

input,
textarea,
select {
    @apply w-full px-2 pt-2 pb-1 rounded-sm bg-white;
}

input,
textarea,
select {
    box-shadow: 0 0 2px 2px transparent;
    @apply text-xs font-bold;
    color: #000;
    transition: box-shadow ease-in-out 400ms;
}

input:placeholder-shown,
textarea:placeholder-shown {
    @apply font-normal text-xs;
    color: #555;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 2px 2px var(--focus-color);
}
input[type=range]:focus {
    box-shadow: none;
}

input:focus:placeholder-shown::placeholder,
textarea:focus:placeholder-shown::placeholder {
    color:transparent;
}

input.invalid, textarea.invalid, .field-checkbox.invalid {
    box-shadow: 0 0 0 2px var(--alert-color);
}

input:required {
    background: var(--asterisk) no-repeat 98% 50% #fff;
    background-size: 8px;
}

input[type="checkbox"], input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
    padding: 0;
    margin: 0;
}

input[type="checkbox"] + label, input[type="radio"] + label {
    line-height: 1.4rem;
}

input[type="checkbox"] + label::before, input[type="radio"] +label::before {
    @apply w-5 h-5 bg-white inline-block mr-2 border;
    text-shadow: none;
    border-color: transparent;
}

input[type="checkbox"] + label::before {
    @apply rounded-sm;
    color: var(--focus-color);
    font-size: 1.2rem;
    font-weight: bold;
    content: "✔";
    color: transparent;
    line-height: 1.2rem;
}

input[type="checkbox"]:checked + label::before {
    background: var(--focus-color);
    color: white;
    content: "✔";
    padding-left: 0.1rem;
    padding-bottom: 0.2rem;
}

input[type="radio"] + label::before {
    @apply rounded-full;
    content: "✔";
    color: transparent;
}

input[type="radio"]:checked + label::before {
    box-shadow: 0px 0px 0px 0.3rem var(--focus-color) inset;
}

/*
input[type="radio"]:checked + label {
}
*/

input[type="checkbox"]:focus, input[type="radio"]:focus {
    box-shadow: none;
}

input[type="checkbox"] + label, input[type="radio"] + label {
    @apply w-auto;
    cursor: pointer;
    display: inline-block;
    text-transform: none;
}

input[type="checkbox"]:focus + label, input[type="radio"]:focus + label {
    /*box-shadow: 0 0 2px 2px rgba(var(--focus-color), 0.3);*/
    box-shadow: none;
    opacity: 0.7;
}

.button.checked {
  border: 2px solid var(--focus-color);
}

.required {
    @apply px-1;
    color:var(--alert-color);
}

$track-color: #fff !default;
$thumb-color: var(--focus-color) !default;

$thumb-radius: 10px !default;
$thumb-height: 20px !default;
$thumb-width: 20px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 1px !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 6px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 3px !default;
$track-border-color: var(--focus-color) !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  cursor: default;
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  box-sizing: border-box;
  cursor: default;
  height: $thumb-height;
  width: $thumb-width;
}

[type='range'] {
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  margin: math.div($thumb-height, 2) 0;
  width: $track-width;

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: 0;

    &::-webkit-slider-runnable-track {
      background: lighten($track-color, $contrast);
    }

    &::-ms-fill-lower {
      background: $track-color;
    }

    &::-ms-fill-upper {
      background: lighten($track-color, $contrast);
    }
  }

  &::-webkit-slider-runnable-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: (math.div(-$track-border-width * 2 + $track-height, 2) - math.div($thumb-height, 2));
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: math.div($track-height, 2);
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: math.div($thumb-height, 2) 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb;
    margin-top: math.div($track-height, 4);
  }

  &:disabled {
    &::-webkit-slider-thumb,
    &::-moz-range-thumb,
    &::-ms-thumb,
    &::-webkit-slider-runnable-track,
    &::-ms-fill-lower,
    &::-ms-fill-upper {
      cursor: not-allowed;
    }
  }
}

.popper {
  width: auto;
  max-width: 300px;
  text-transform: none;
  background-color: #FFFFE1;
  color: #444;
  text-align: center;
  padding: 4px;
  display: inline-block;
  border-radius: 3px;
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  border: 1px #ebebeb solid;
  z-index: 200000;
  box-shadow: 0px 2px 1px 0px rgba(0,0,0,0.33);
}
.popper .popper__arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}
.popper[x-placement^="top"] {
  margin-bottom: 5px;
}
.popper[x-placement^="top"] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #FFFFE1 transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.popper[x-placement^="bottom"] {
  margin-top: 5px;
}
.popper[x-placement^="bottom"] .popper__arrow {
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #FFFFE1 transparent;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.popper[x-placement^="right"] {
  margin-left: 5px;
}
.popper[x-placement^="right"] .popper__arrow {
  border-width: 5px 5px 5px 0;
  border-color: transparent  #FFFFE1 transparent transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.popper[x-placement^="left"] {
  margin-right: 5px;
}
.popper[x-placement^="left"] .popper__arrow {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #FFFFE1;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: $xl) {

    label.conditional {
        font-size: 0.62rem;
        line-height: 0.7rem;
        margin-top: -0.5em;
    }
    
    label.conditional span {
        @apply pl-3 invisible;
    }

    input,
    textarea,
    select {
        @apply px-3 pr-6 py-2 rounded-md;
    }

    option {
        @apply py-1;
    }

    input[type="checkbox"] + label, input[type="radio"] + label {
        @apply p-2 pl-0;
    }

    input,
    textarea,
    select {
        @apply text-base;
    }

    input:placeholder-shown,
    textarea:placeholder-shown {
        @apply text-base;
    }

}

</style>