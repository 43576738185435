//import Vue from 'vue'
import store from '../store/store.js'
//import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Settings from '../views/Settings.vue'

import List from '../views/List.vue'
import Edit from '../views/Edit.vue'
import Command from '../views/Command.vue'
import Package from '../views/Package.vue'
import Stat from '../views/Stat.vue'
import Upload from '../views/Upload.vue'
import Add from '../views/Add.vue'

const appConfig = require('../config.js')
const config = appConfig(process.env.NODE_ENV == "production");

const schema = require('../schema.js')()

//Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Bejelentkezés',
            noauth: true,
        },
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Főoldal',
        },
    },
    {
        path: '/list/:table',
        name: 'List',
        component: List,
    },
    {
        path: '/edit/:table/:id',
        name: 'Edit',
        component: Edit,
    },
    {
        path: '/command/:table/:command',
        name: 'Command',
        component: Command,
    },
    {
        path: '/command/:table/:command/:param',
        name: 'CommandGo',
        component: Command,
    },
    {
        path: '/command/:table/:command/:param/:param2',
        name: 'CommandGo2',
        component: Command,
    },
    {
        path: '/upload/:table/:command',
        name: 'Upload',
        component: Upload,
    },
    {
        path: '/packaging/',
        name: 'Package',
        component: Package,
    },
    {
        path: '/packaging/:param',
        name: 'PackageGo',
        component: Package,
    },
    {
        path: '/stat/:table/:command',
        name: 'Stat',
        component: Stat,
    },
    {
        path: '/stat/:table/:command/:param',
        name: 'StatGo',
        component: Stat,
    },
    {
        path: '/stat/:table/:command/:param/:param2',
        name: 'StatGo2',
        component: Stat,
    },
    {
        path: '/add/:table/',
        name: 'Add',
        component: Add,
    },    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            title: 'Beállítások',
            icon: 'sliders-h',
            rightMenu: true
        },
    },
]

/*
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})
*/
const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    let auth = true
    if (to.meta && to.meta.noauth) {
        auth = false
    }
    if (auth && !store.state.user.authenticated) {
        next('/login')
    } else {
        const nearestWithTitle = to.matched
            .slice()
            .reverse()
            .find((r) => r.meta && r.meta.title)
        if (nearestWithTitle) document.title = nearestWithTitle.meta.title
        Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el))
        next()
    }
})

export default router
