//import Vue from 'vue'
import Vuex from 'vuex'
import userf from './user.js'

import VuexPersistence from 'vuex-persist'

const appConfig = require('../config.js')
const config = appConfig(process.env.NODE_ENV == "production")

const schema = require('../schema.js')()

const vuexLocal = new VuexPersistence({
    key: config.storeId,
    storage: window.localStorage,
    /*saveState: (key, state, storage) => {
        let t1 = performance.now()
        let data = JSON.stringify(state)
        storage.setItem(key, data)
        let t2 = performance.now()
        console.log('storage took ', t2 - t1)
    }*/
})

let storeMain = {
    state: {
        _init: false,
        tables: {},
        messages: [],
        _uid: 0,
        _rates: false,
    },
    actions: {
        async init ({commit, state }) {
            //console.log("store init")
            if (!state._init) {
                commit('init')
            }
            commit('initSchema')
            let ts = Math.floor(Date.now() / 1000)
            let rrates = true
            if (state._rates) {
                let age = ts - state._rates.timestamp
                if (age < 21600) {
                    rrates = false
                }
            }
            if (rrates) {
                //console.log("store get rates")
                let resrates = await fetch('https://static.internetprint.eu/rates/latest.json', {
                    mode: 'cors',
                    cache: 'no-cache',
                })
                let rates = await resrates.json()
                rates.timestamp = ts
                commit('setRates', rates)
            }
            //console.log("rates:",state._rates)
        },
    },
    mutations: {
        initSchema: (state) => {
            for (let name in schema) {
                if (!state.tables[name]) {
                    let table = schema[name]
                    let s = {
                        filterForm: {},
                        filter: {},
                        sort: {},
                        skip: 0,
                        limit: table.limit ? table.limit : 10,
                        selected: {}
                    }
                    if (name== 'orders') {
                        s['filterForm'] = {archive: 0}
                        s['filter'] = {archive: 0}
                    }
                    s.sort[table.sort] = table.sort_dir
                    state.tables[name] = s
                }
            }
        },
        init: (state) => {
            state._init = true
        },
        tableSetting: (state, p) => {
            state.tables[p.table][p.key] = p.value
        },
        setRates(state, rates) {
            state._rates = rates
        },
        toggleSelect: (state, p) => {
            if (state.tables[p.table].selected[p._id]) {
                delete state.tables[p.table].selected[p._id]
            } else {
                state.tables[p.table].selected[p._id] = true
            }
        },
        clearSelect: (state, p) => {
            state.tables[p.table].selected = {}
        },
        filterSetting: (state, p) => {
            state.tables[p.table].filterForm[p.key] = p.value
        },
        filterReset: (state, p) => {
            if (p.table == 'orders') {
                state.tables[p.table].filterForm = {archive: 'alive'}
                state.tables[p.table].filter = {archive: 'alive'}
            } else {
                state.tables[p.table].filterForm = {}
                state.tables[p.table].filter = {}
            }
            state.tables[p.table].selected = {}
            state.tables[p.table].skip = 0
        },
        message: (state, p) => {
            p.id = state._uid++
            state.messages.push(p)
        },
        clearMessages: (state) => {
            state.messages = []
        },
        hideMessage: (state, id) => {
            state.messages.forEach(m => {
                if (m.id == id) {
                    m.hidden = true
                }
            })
        },
        deleteMessage: (state, id) => {
            state.messages = state.messages.filter(m => m.id != id)
        },
    },
    modules: {},
}

storeMain.plugins = [vuexLocal.plugin]
let user = userf(config)

storeMain.modules = {
    user,
}

//Vue.use(Vuex)
//const store = new Vuex.Store(storeMain)
const store = Vuex.createStore(storeMain)

store.API = async function (service, data, method = 'POST') {
    try {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        if (this.state.user.token) {
            headers['Authorization'] = 'Bearer ' + this.state.user.token
        }
        let bodyContent
        /* eslint-disable */
        if (typeof(CompressionStream) != 'undefined') {
            async function compressGzip(input) {
                const stream = new Response(input).body.pipeThrough(
                  new CompressionStream('gzip')
                )
                return new Response(stream).blob()
            }
            bodyContent = await compressGzip(JSON.stringify(data))
            headers['content-encoding'] = 'gzip'
        } else {
            bodyContent = JSON.stringify(data)
        }
        /* eslint-enable */
        const response = await fetch(config.api.store + service, {
            method: method,
            headers: headers,
            body: bodyContent
        })
        const content = await response.json();
        if (content.error) {
            throw content.error
        } else {
            return content
        }
    } catch(error) {
      console.error('API ERROR '+service+':', error)
      return false
    }
}

store.processAPI = async function (service) {
    if (config.api.notify) {
        try {
            let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            let options = {
                method: 'GET',
                headers: headers,
            }
            const response = await fetch(config.api.notify + service, options)
            const content = await response.json()
            if (content.error) {
                throw content.error
            } else {
                return content
            }
        } catch(error) {
            console.error('process API ERROR '+service+':', error)
            return false
        }
    } else {
        return false
    }
}

store.NOTIFY = async function () {
    return await store.processAPI('notify')
}

store.dispatch('init')

export default store
